<!-- header -->

<!-- main content -->
<div class="page-container">
  <!-- back button -->
  <section class="back-button-container">
    <div class="back-btn" [matDialogClose]>
      <mat-icon>navigate_before</mat-icon>
      <div>Back</div>
    </div>
  </section>

  <div *ngIf="files.length === 1">
    <app-content-nft-player-renderer [spec]="contentSpec" [content]="files[0]"></app-content-nft-player-renderer>
  </div>
  <div *ngIf="files.length >= 2">
    <swiper-container #swiperRef init="false">
      <ng-container *ngFor="let file of files; index as i">
        <swiper-slide style="height: auto" [data-index]="i">
          <app-content-nft-player-renderer [spec]="contentSpec" [content]="file"></app-content-nft-player-renderer>
        </swiper-slide>
      </ng-container>
    </swiper-container>
    <div #swiperPagination class="custom-swiper-pagination"></div>      <!-- <div class="swiper-pagination"></div> -->
  </div>
</div>

<!-- <div class="swiper">
  <div class="swiper-wrapper">
    <ng-container *ngFor="let file of files">
      <div class="swiper-slide">
        <app-content-nft-player-renderer [spec]="contentSpec" [content]="file"></app-content-nft-player-renderer>
      </div>
    </ng-container>
  </div>
  <div class="swiper-pagination"></div>
</div> -->
