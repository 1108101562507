import { Component, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-mint-dialog',
  templateUrl: './mint-dialog.component.html',
  styleUrls: ['./mint-dialog.component.scss'],
})
export class MintDialogComponent {
  form: FormGroup = this.fb.group({
    amount: [
      null,
      [
        Validators.required,
        Validators.min(1),
        Validators.minLength(8),
        Validators.pattern('^([1-9][0-9]*)$'),
      ]
    ],
  });

  constructor(
    private dialogRef: MatDialogRef<
      MintDialogComponent,
      string | undefined
    >,
    private fb: FormBuilder
  ) {}

  done(): void {
    const amount = this.form.getRawValue().amount;
    this.dialogRef.close(amount);
  }
}
@Injectable({
  providedIn: 'root',
})
export class MintDialogService {
  constructor(private dialog: MatDialog) {}
  /** dialog open */
  async open(): Promise<string | undefined> {
    const ref = this.dialog.open(MintDialogComponent, {
      width: '90%',
      maxWidth: '390px',
      height: '270px',
    });
    const res = await firstValueFrom(ref.afterClosed());
    return res;
  }
}
