<ngx-spinner type="square-jelly-box" size="medium" [fullScreen]="true">
  <p style="color: white">Processing...</p>
</ngx-spinner>
<!-- header -->
<app-header></app-header>

<!-- main content -->
<div class="page-container">
  <!-- back button -->
  <section class="back-button-container">
    <div class="back-btn" (click)="back()">
      <mat-icon>navigate_before</mat-icon>
      <div>Back</div>
    </div>
  </section>
  <h1 class="mint-title">{{ contractName }}</h1>
  <div class="input-wrapper">
    <form [formGroup]="form">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Product Name</mat-label>
        <input matInput formControlName="name" placeholder="Max 30 characters" maxlength="30" />
      </mat-form-field>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" placeholder="Max 100 characters" maxlength="100" />
      </mat-form-field>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Total Supply (1 - 100)</mat-label>
        <input matInput type="number" formControlName="totalSupplyLimit" />
      </mat-form-field>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Agreement</mat-label>
        <input matInput formControlName="contractDoc" placeholder="Max 250 characters" maxlength="250" />
      </mat-form-field>
      <!--
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Genre</mat-label>
        <mat-select formControlName="selectedGenre">
          <mat-option *ngFor="let genre of genres" [value]="genre.name">
            {{ genre.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      -->
      <mat-radio-group
        class="radio-group"
        aria-label="Select an option"
        formControlName="allowSecondaryMerket"
      >
        <p style="padding-left: 0.75em;">Secondary</p>
        <div class="radio-group" style="width: 65%; justify-content: space-around; font-size: smaller;">
          <mat-radio-button value="0"> <p>Permitted</p> </mat-radio-button>
          <mat-radio-button value="1"> <p>Not permitted</p> </mat-radio-button>
        </div>
      </mat-radio-group>
      <mat-form-field class="example-full-width" appearance="fill" *ngIf="form.getRawValue().allowSecondaryMerket == 0">
        <mat-label>Royalty(%)</mat-label>
        <input matInput type="number" formControlName="royalty" />
      </mat-form-field>
      <!-- <mat-radio-group
        class="radio-group"
        aria-label="Select an option"
        formControlName="contentCopy"
      >
        <p style="padding-left: 0.75em;">Content copy</p>
        <div class="radio-group" style="width: 65%; justify-content: space-around; font-size: smaller;">
          <mat-radio-button value="0" [disabled]="form.getRawValue().personalInfo == 0"> <p [style.opacity]="form.getRawValue().personalInfo == 0 ? 0.6 : null">Permitted</p> </mat-radio-button>
          <mat-radio-button value="1"> <p>Not permitted</p> </mat-radio-button>
        </div>
      </mat-radio-group> -->
      <mat-radio-group
        class="radio-group"
        style="margin-bottom: 21px;"
        aria-label="Select an option"
        formControlName="personalInfo"
      >
        <p style="padding-left: 0.75em;">Personal information</p>
        <div class="radio-group" style="width: 65%; justify-content: space-around; font-size: smaller;">
          <mat-radio-button value="0"> <p>Contained</p> </mat-radio-button>
          <mat-radio-button value="1"> <p>Not contained</p> </mat-radio-button>
        </div>
      </mat-radio-group>
      <!-- プロダクトイメージ画像 -->
      <input
        #productImageInput
        type="file"
        name="product-image"
        id="product-image"
        accept="image/*"
        style="display: none"
        (change)="onChangeFileInput($event)"
      />
      <div class="file-upload-btn" *ngIf="contentType !== 'card' && !productImgSrc" style="cursor: pointer">
        <label
          for="product-image"
          class="label-btn"
          style="cursor: pointer; margin: unset; padding: 14px 0 14px;"
        >Choose Product Image
        </label>
      </div>
      <div class="img-container" *ngIf="productImgSrc">
        <label for="product-image">
          <img [src]="productImgSrc" alt="" style="width: 100%" />
        </label>
      </div>
      <div *ngFor="let fileInfo of fileInfos; index as i" class="img-container">
        <label *ngIf="fileInfo.type === 'card'" for="file-{{ i }}">
          <img [src]="fileInfo.src" alt="" style="width: 100%" />
        </label>
        <label *ngIf="fileInfo.type === 'movie'" for="file-{{ i }}">
          <video [src]="fileInfo.src" style="width: 100%; pointer-events: none" controls></video>
        </label>
        <label *ngIf="fileInfo.type === 'music'" for="file-{{ i }}">
          <audio [src]="fileInfo.src" style="pointer-events: none" controls></audio>
        </label>
      </div>
      <!-- プロダクト画像/音声/動画 -->
      <div class="file-upload-btn" *ngIf="contentType !== 'book' && fileInfos.length === 0" style="margin-top: 5vh; cursor: pointer">
        <label
          for="file-0"
          class="label-btn"
          style="cursor: pointer; margin: unset; padding: 14px 0 14px;"
        >Choose File
        </label>
      </div>
      <div class="file-upload-btn" *ngIf="contentType === 'book'" style="margin-top: 5vh; cursor: pointer">
        <label
          for="file-{{ fileInfos.length }}"
          class="label-btn"
          style="cursor: pointer; margin: unset; padding: 14px 0 14px;"
        >Add File
        </label>
      </div>
      <ng-container *ngFor="let _ of [].constructor(fileInfos.length + 1); index as i">
        <input
          #fileInput
          type="file"
          id="file-{{ i }}"
          [accept]="acceptFileType"
          style="display: none"
          (change)="onChangeFileInput($event, i)"
        />
      </ng-container>
      <button
        type="button"
        class="primary-btn"
        style="margin-top: calc(20px + 5vh);"
        [disabled]="form.invalid || fileInfos.length === 0 || (contentType !== 'card' && !productImgSrc)"
        (click)="onSubmit(true)"
      >
        Create NFT
      </button>
    </form>
  </div>
</div>
