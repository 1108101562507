<section class="column">
  <div class="column message">
    <p style="display: block; width: 100%; text-align: left">
      Enter amount
    </p>
    <form [formGroup]="form">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Amount</mat-label>
        <input
          matInput
          id="amount"
          type="number"
          autocomplete="off"
          formControlName="amount"
          placeholder="Amount"
        />
      </mat-form-field>
    </form>
  </div>
  <div class="space btn-box">
    <button class="primary-btn" (click)="done()" [disabled]="this.form.invalid">
      Continue
    </button>
  </div>
</section>
