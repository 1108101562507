import { Platform } from '@angular/cdk/platform';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { AppService } from './services/app/app.service';
import { StorageService } from './services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Sanpō Wallet β';
  loaded: Observable<boolean> | null = null;
  isShow = false;
  get isMobile() {
    return this.platform.ANDROID || this.platform.IOS;
  }
  constructor(
    private appService: AppService,
    private storageService: StorageService,
    private platform: Platform,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    this.loaded = this.appService.loaded$;
    iconRegistry.addSvgIcon(
      'circle-arrow-right-solid',
      sanitizer.bypassSecurityTrustResourceUrl('assets/image/circle-arrow-right-solid.svg'));
    iconRegistry.addSvgIcon(
      'circle-right-regular',
      sanitizer.bypassSecurityTrustResourceUrl('assets/image/circle-right-regular.svg'));
    iconRegistry.addSvgIcon(
      'images-regular',
      sanitizer.bypassSecurityTrustResourceUrl('assets/image/images-regular.svg'));
    iconRegistry.addSvgIcon(
      'key-solid',
      sanitizer.bypassSecurityTrustResourceUrl('assets/image/key-solid.svg'));
    iconRegistry.addSvgIcon(
      'pen-to-square-regular',
      sanitizer.bypassSecurityTrustResourceUrl('assets/image/pen-to-square-regular.svg'));
    iconRegistry.addSvgIcon(
      'triangle-exclamation-solid',
      sanitizer.bypassSecurityTrustResourceUrl('assets/image/triangle-exclamation-solid.svg'));
    iconRegistry.addSvgIcon(
      'wallet-solid',
      sanitizer.bypassSecurityTrustResourceUrl('assets/image/wallet-solid.svg'));
    iconRegistry.addSvgIcon(
      'bars-solid',
      sanitizer.bypassSecurityTrustResourceUrl('assets/image/bars-solid.svg'));
  }
  async ngOnInit() {
    this.appService.init();
    this.loaded?.subscribe(() => {
      this.isShow = true;
    });
  }
  async ngOnDestroy(): Promise<void> {
    await this.storageService.clearTransactionHash();
  }
}
