<ngx-spinner type="square-jelly-box" size="medium" [fullScreen]="true">
  <p style="color: #ffffff">Processing...</p>
</ngx-spinner>
<!-- header -->
<app-header></app-header>

<!-- main content -->
<div class="page-container">
  <!-- back button -->
  <section class="back-button-container">
    <div class="back-btn" routerLink="/content-nft-list">
      <mat-icon>navigate_before</mat-icon>
      <div>Back</div>
    </div>
  </section>

  <!-- title -->
  <div class="main-container">
    <div class="img-container" *ngIf="imageSource">
      <img src="{{ imageSource }}" (error)="onImgError()" (click)="onClickProductImage()" />
    </div>
    <h1 class="design-title" *ngIf="spec">{{ spec.name }}</h1>
    <dl>
      <div class="listspace">
        <dt>Product ID</dt>
        <dd>{{ spec?.specId }}</dd>
      </div>
      <div class="listspace">
        <dt>Serial No</dt>
        <dd *ngIf="objectIndex.length > 0">{{ objectIndex }}</dd>
      </div>
      <!--
      <div class="listspace">
        <dt>Product No</dt>
        <dd *ngIf="objectId.length > 0">{{ objectId }}</dd>
      </div>
      -->
      <div class="listspace" *ngIf="imageSource">
        <dt>Media ID</dt>
        <dd>{{ spec.mediaId }}</dd>
      </div>
      <ng-container *ngIf="showMore && specInfo">
        <!--
        <div class="listspace">
          <dt>Content Type</dt>
          <dd *ngIf="spec?.contentType">
            {{ spec.contentType }}
          </dd>
          <dd *ngIf="!spec.contentType">-</dd>
        </div>
        -->
        <div class="listspace">
          <dt>Description</dt>
          <dd>{{ description }}</dd>
        </div>
        <div class="listspace">
          <dt>Minted / Total Supply Limit</dt>
          <dd *ngIf="spec.totalSupplyLimit">
            {{ spec?.totalSupply }} / {{ spec?.totalSupplyLimit }}
          </dd>
          <dd *ngIf="!spec?.totalSupplyLimit">-</dd>
        </div>
        <!--
        <div class="listspace">
          <dt>Original SpecIds</dt>
          <dd *ngIf="spec?.originalSpecIds?.length! > 0">
            <ng-container *ngFor="let value of spec?.originalSpecIds">
              {{ value }}
            </ng-container>
          </dd>
          <dd *ngIf="spec?.originalSpecIds?.length! === 0">-</dd>
        </div>
        -->
        <div class="listspace">
          <dt>Agreement</dt>
          <dd *ngIf="spec?.agreements?.length! > 0">
            <ng-container *ngFor="let value of spec?.agreements">
              {{ value }}
            </ng-container>
          </dd>
          <dd *ngIf="spec?.agreements?.length! === 0">-</dd>
        </div>
        <div class="listspace">
          <dt>Secondary</dt>
          <dd *ngIf="spec?.secondarySales">
            Permitted
          </dd>
          <dd *ngIf="!spec?.secondarySales">
            Not permitted
          </dd>
        </div>
        <div class="listspace">
          <dt>Royalty(%)</dt>
          <dd *ngIf="spec?.secondarySales">
            <ng-container *ngFor="let value of spec?.royalty">
              {{ value }}
            </ng-container>
          </dd>
          <dd *ngIf="!spec?.secondarySales">-</dd>
        </div>
        <div class="listspace">
          <dt>Symbol</dt>
          <dd>{{ spec.name }}</dd>
        </div>
        <div class="listspace">
          <dt>Content copy</dt>
          <dd>{{ spec.drm ? 'Not permitted' : 'Permitted' }}</dd>
        </div>
        <div class="listspace">
          <dt>Personal information</dt>
          <dd>{{ spec.personaInformation ? 'Not contained' : 'Contained' }}</dd>
        </div>
        <div class="listspace">
          <dt>Genre</dt>
          <dd>Anime</dd>
        </div>

        <!--
        <ng-container *ngFor="let info of specInfo | keyvalue">
          <div class="listspace">
            <dt>{{ info.key }}</dt>
            <ng-container
              *ngIf="typeCheck(info.value) === 'object'; else string"
            >
              <dd>
                <ng-container *ngFor="let value of info.value | keyvalue">
                  {{ value.key }}:{{ value.value }}
                </ng-container>
              </dd>
            </ng-container>
            <ng-template #string>
              <dd>
                {{ info.value }}
              </dd>
            </ng-template>
          </div>
        </ng-container>
        -->
      </ng-container>
      <p class="text-right more" (click)="onClickMore()">
        <mat-icon>
          {{ showMore ? "arrow_circle_up" : "arrow_circle_down" }}
        </mat-icon>
        {{ showMore ? "close" : "Display Product information" }}
      </p>
    </dl>
  </div>
  <!--
  <div class="img-container" *ngIf="imageSource">
    <img src="{{ imageSource }}" (error)="onImgError()"/>
  </div>
  -->
  <section class="btn-container" *ngIf="mode === 1">
    <button class="primary-btn" (click)="toTransfer()">
      Transfer to others
    </button>
  </section>

  <section class="form-wrapper" *ngIf="mode === 2">
    <form [formGroup]="addressForm">
      <mat-form-field appearance="fill">
        <mat-label>To:</mat-label>
        <input matInput formControlName="address" class="address-input" />
      </mat-form-field>
      <button mat-flat-button (click)="openAddressBook()" class="drop-down-button">
        <mat-icon class="drop-down-icon">arrow_drop_down_circle</mat-icon>
      </button>
      <mat-form-field appearance="fill">
        <mat-label>Serial No:</mat-label>
        <mat-select formControlName="selectedObjectId" placeholder="Select Serial No">
          <mat-option *ngFor="let item of select" [value]="item.objectId">
            {{ item.objectIndex }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </section>
  <section class="btn-container" *ngIf="mode === 2">
    <button class="primary-btn" (click)="transfer()" [disabled]="!valid()">
      Transfer
    </button>
  </section>
</div>
