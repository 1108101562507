<ng-container *ngIf="content.type === 'card'">
  <div *ngIf="spec.drm; else drmElse" class="protected-message">
    <span>
      This content is copy protected and<br />
      cannot be displayed on the web browser.<br />
      <br />
      Please use the app to view the image.
    </span>
  </div>
  <ng-template #drmElse>
    <img class="content" [src]="mediaSource" />
  </ng-template>
</ng-container>
<ng-container *ngIf="content.type === 'music'">
  <div class="content">
    <div
      class="audio-player"
      [style.background-image]="'url(' + thumbnailSource + ')'"
      (click)="toggleAudio()"
    >
      <audio
        #audioRef
        [src]="mediaSource"
        style="margin: 1.5em; width: 100%"
        controls
      ></audio>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="content.type === 'movie' && !spec.drm">
  <div class="content">
    <video
      [src]="mediaSource"
      controls
      style="width: 100%"
    ></video>
  </div>
</ng-container>
<ng-container *ngIf="content.type === 'movie' && spec.drm">
  <div class="content">
    <app-shaka-player
      [source]="drmMediaSource"
      [specId]="spec.specId"
      style="width: 100%"
    ></app-shaka-player>
  </div>
</ng-container>
