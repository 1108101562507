<div class="header-wrapper">
  <img src="assets/image/sanpo_logo.png" alt="" class="sanpo-logo-header" />
  <section class="header-container" style="flex-direction: row; justify-content: space-between;">
    <div style="width: 40px;">&nbsp;</div>
    <div class="header-text">
      <h1>Sanpō Wallet β</h1>
    </div>

    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" style="max-width: 350px;">
      <span mat-menu-item style="cursor: unset; pointer-events: none;">
        <mat-icon>person</mat-icon>
        <span>{{ id }}</span>
      </span>
      <button mat-menu-item (click)="copyWalletAddress()">
        <mat-icon>
          <img src="assets/image/sanpo_logo.png" style="width: 100%; filter: brightness(0.4);" />
        </mat-icon>
        <span style="font-size: 12px;">{{ walletAddress }}</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
      <!--
      <button mat-menu-item (click)="showInformation()">
        <mat-icon>info</mat-icon>
        <span>About Sanpō Wallet</span>
      </button>
      -->
    </mat-menu>
  </section>
</div>