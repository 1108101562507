import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from 'src/app/services/auth/auth.service';
import { KeyService } from 'src/app/services/key/key.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { InformationDialogService } from 'src/app/shared/components/information-dialog/information-dialog.component';

import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  walletAddress = '';
  id = '';

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private headerService: HeaderService,
    private keyService: KeyService,
    private storageService: StorageService,
    private authService: AuthService,
    private clipboardService: ClipboardService,
    private informationDialogService: InformationDialogService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.walletAddress = (await this.storageService.getWalletAddress()) ?? '';
    this.id = await this.keyService.getDecryptEmailAddress();
  }

  copyWalletAddress() {
    this.clipboardService.copy(this.walletAddress);
    this.snackBar.open('Copied your wallet address.', 'OK', {
      duration: 4000, // 4s
      panelClass: ['brown-snackbar'],
      verticalPosition: 'bottom',
    });
  }

  async logout() {
    await this.authService.logOut();
  }

  async showInformation() {
    this.informationDialogService.open();
  }
}

@Injectable({providedIn: 'root'})
export class HeaderService {
  visible = false;
  constructor() {
    this.visible = false;
  }
  hide() {
    this.visible = false;
  }
  show() {
    this.visible = true;
  }
}
