<section class="column">
  <div class="column message">
    <p style="display: block; width: 100%; text-align: left">
      Enter wallet password
    </p>
    <form [formGroup]="form">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Wallet password</mat-label>
        <input
          matInput
          id="password"
          type="password"
          autocomplete="off"
          formControlName="password"
          placeholder="password"
        />
      </mat-form-field>
      <!-- <label for="password">
      Please enter the wallet password
    </label>
    <input class="mb-5"
      id="password"
      type="password"
      formControlName="password"
      autocomplete="off"
      placeholder="Enter your wallet password" /> -->
    </form>
  </div>
  <div class="space btn-box">
    <button class="primary-btn" (click)="done()" [disabled]="this.form.invalid">
      Continue
    </button>
  </div>
</section>
