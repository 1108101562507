import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-nft-menu',
  templateUrl: './nft-menu.component.html',
  styleUrls: ['./nft-menu.component.scss']
})
export class NftMenuComponent implements OnInit {
  address = '';

  constructor(
    private router: Router,
    private storageService: StorageService,
  ) {
  }

  async ngOnInit() {
    this.address = (await this.storageService.getWalletAddress()) ?? '';
  }

  navigateCreateBrand() {
    this.router.navigate(['/create-brand']);
  }

  navigateMyNfts() {
    this.router.navigate(['/content-nft-list']);
  }

}
