<!-- header -->
<app-alt-header></app-alt-header>

<!-- main content -->
<div class="page-container">
  <div class="card-wrapper">
    <div class="card-container" style="margin-top: 50px; cursor: pointer; height: 80px;" (click)="navigateLogin()">
      <div class="flex-row" style="width: calc(100% - 40px); height: 100%; justify-content: flex-start;">
        <span class="material-symbols-outlined" style="font-size: 20px; margin-right: 20px;">login</span>
        <h3>Login</h3>
      </div>
    </div>
    <div class="flex-row" style="margin-top: 70px;">
      <div class="flex-row" style="width: calc(100% - 40px); cursor: pointer;  justify-content: flex-start;"
        (click)="navigateImport()">
        <mat-icon class="icon" svgIcon="key-solid"></mat-icon>
        <h4 style="text-decoration: underline; margin: unset;">Import my account</h4>
      </div>
    </div>
    <p style="margin: unset; padding-left: 60px;">If you have a secret key, choose here.</p>
    <div class="flex-row" style="margin-top: 50px;">
      <div class="flex-row" style="width: calc(100% - 40px); cursor: pointer;  justify-content: flex-start;"
        (click)="navigateCreate()">
        <mat-icon class="icon" svgIcon="wallet-solid"></mat-icon>
        <h4 style="text-decoration: underline;">Create new wallet</h4>
      </div>
    </div>
  </div>
</div>
