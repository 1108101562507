<ngx-spinner type="square-jelly-box" size="medium" [fullScreen]="true">
  <p style="color: white">Processing...</p>
</ngx-spinner>
<!-- header -->
<app-header></app-header>

<!-- main content -->
<div class="page-container">
  <!-- back button -->
  <section class="back-button-container">
    <div class="back-btn" (click)="backTo()">
      <mat-icon>navigate_before</mat-icon>
      <div>Back</div>
    </div>
    <div style="display: flex; align-items: center">
      <button type="button" class="primary-btn-short button-add-brand" (click)="openAddBrand()">
        Add Brand
      </button>
      <button mat-icon-button (click)="ngOnInit()">
        <mat-icon>cached</mat-icon>
      </button>
    </div>
  </section>

  <!-- title -->
  <h1 class="design-title">My Gallery</h1>

  <mat-chip-list multiple>
    <mat-chip
      *ngFor="let genre of genres"
      [selected]="selectedGenres.includes(genre.key)"
      (click)="toggleGenre(genre.key)"
    >
      {{ genre.name }}
    </mat-chip>
  </mat-chip-list>

  <section class="main-container" *ngFor="let brand of filteredBrands">
    <!-- ブランド名タブ -->
    <div class="list-title" (click)="clickName(brand.address)">
      <span class="brand-name">{{ brand.name }}</span>
      <mat-icon class="icon-copy" (click)="$event.stopPropagation(); copyContractAddress(brand.address);">file_copy</mat-icon>
    </div>
    <div style="position: relative; width: 100%;" *ngIf="brand.creator === walletAddress">
      <button type="button" class="primary-btn-short button-add" (click)="navigateMint(brand.name, brand.address)">
        Add Product
      </button>
    </div>
    <ul id="{{ brand.address + '_dummy' }}" style="display: none;"></ul>

    <!-- プロダクト情報 -->
    <ul id="{{ brand.address + '_list' }}">
      <li *ngFor="let product of getProductsForBrand(brand)" (click)="navigateDetail(brand.address, product)">
        <div id="{{ product.spec.specId + '_loading' }}" class="text-mint-ready" *ngIf="product.jobStatus === true">Ready to mint</div>
        <div id="{{ product.spec.specId + '_loading' }}" class="text-loading" *ngIf="product.jobStatus === false">In progress...</div>
        <div id="{{ product.spec.specId + '_loading' }}" class="text-loading" *ngIf="product.jobStatus === undefined && !product.image">Loading...</div>
        <div id="{{ product.spec.specId + '_alert' }}" style="display: none; text-align: center;">
          <mat-icon svgIcon="triangle-exclamation-solid" style="color: red;"></mat-icon>
          <p class="error">Could not display this Image.</p>
          <p id="{{ product.spec.specId + '_error' }}" class="error">Oops! Something went wrong!</p>
        </div>
        <img id="{{ product.spec.specId + '_img' }}" class="image" src="{{ product.image }}" />
        <p style="text-align: center; margin: unset;">{{ product.spec.name }}</p>
      </li>
      <li *ngIf="(brand.products.length + draftContents[brand.address]?.length ?? 0) === 0">(No NFTs)</li>
    </ul>
    <p id="{{ brand.address + '_showmore' }}" class="show-more" (click)="clickName(brand.address)">show more...</p>
  </section>

  <section class="main-container" *ngIf="ready && filteredBrands.length === 0">
    <p style="text-align: center;">No NFTs.</p>
    <p style="text-align: center;">To add a existing brand, Tap <span (click)="openAddBrand()" class="text-link">Add Brand</span>.</p>
    <p style="text-align: center;">To create a new Brand, Tap <span (click)="navigateCreateBrand()" class="text-link">Create new Brand</span>.</p>
  </section>
</div>
