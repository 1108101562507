import {
  OnInit,
  Component,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  OnDestroy,
} from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { PublicStorageService } from 'src/app/services/public-storage/public-storage.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';

declare const shaka: any;

type ProtectedSource = { widevine: string; fairplay: string };

@Component({
  selector: 'app-shaka-player',
  templateUrl: './shaka-player.component.html',
  styleUrls: ['./shaka-player.component.scss'],
})
export class ShakaPlayerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('videoPlayer') videoElementRef: ElementRef | undefined;
  @ViewChild('videoContainer') videoContainerRef: ElementRef | undefined;

  @Input()
  source!: ProtectedSource;

  @Input()
  specId!: string;

  videoElement: HTMLVideoElement | undefined;
  videoContainerElement: HTMLDivElement | undefined;
  player: any;

  constructor(
    private platform: Platform,
    private storageService: StorageService,
    private publicStorageService: PublicStorageService
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    shaka.polyfill.installAll();
    if (shaka.Player.isBrowserSupported()) {
      this.videoElement = this.videoElementRef?.nativeElement;
      this.videoContainerElement = this.videoContainerRef?.nativeElement;
      this.initPlayer();
    } else {
      console.error('Browser not supported!');
    }
  }

  private async initPlayer() {
    this.player = new shaka.Player(this.videoElement);

    // const ui = new shaka.ui.Overlay(
    //   this.player,
    //   this.videoContainerElement,
    //   this.videoElement
    // );

    const useFairplay = this.platform.SAFARI || this.platform.IOS;
    const walletAddress = (await this.storageService.getWalletAddress()) ?? '';
    const cAddress = (await this.storageService.getContractAddress()) || '';
    const playlist = await this.publicStorageService.fileDownload(
      'dummy_token',
      useFairplay ? this.source.fairplay : this.source.widevine,
      walletAddress,
      cAddress,
      this.specId
    );

    let loadPromise!: Promise<unknown>;
    if (useFairplay) {
      const certRes = await fetch('https://example.com/fairplay.cer');
      const cert = await certRes.arrayBuffer();
      this.player.configure({
        preferredAudioLanguage: 'en-US',
        drm: {
          servers: {
            'com.apple.fps.1_0': '[fairplay license server URL]',
          },
          advanced: {
            'com.apple.fps.1_0': {
              serverCertificate: new Uint8Array(cert),
            },
          },
        },
      });

      loadPromise = this.player.load(
        this.source.fairplay,
        0,
        'application/x-mpegURL'
      );
    } else {
      this.player.configure({
        drm: {
          servers: {
            'com.widevine.alpha':
              'https://cwip-shaka-proxy.appspot.com/no_auth',
          },
          advanced: {
            'com.widevine.alpha': {
              videoRobustness: 'SW_SECURE_CRYPTO',
              audioRobustness: 'SW_SECURE_CRYPTO',
            },
          },
        },
      });

      const lines = playlist.trim().split('\n');
      const cidMap = JSON.parse(lines[lines.length - 2]);

      this.player.getNetworkingEngine().registerRequestFilter((type: any, request: any) => {
        const baseUrl = `${environment.ipfsGwUrl}/v2/files/`;
        switch (type) {
          case shaka.net.NetworkingEngine.RequestType.SEGMENT:
            request.headers['x-sanpo-address'] = walletAddress;
            request.headers['x-sanpo-contract'] = cAddress;
            request.headers['x-sanpo-specid'] = this.specId;
            request.uris = request.uris.map((uri: string) => {
              return uri.replace(/^([\w\.]+)$/, (_, template) => {
                const baseUrl = `${environment.ipfsGwUrl}/v2/files/`;
                const cid = cidMap.find((item: any) => item.path === template);
                const mimeQuery = template.includes('_video') ? 'video/mp4' : 'audio/mp4';
                return baseUrl + cid.cid + '?mime=' + mimeQuery;
              });
            });
            break;
          default:
            break;
        }
      });

      // Convert playlist to Data URI
      const playlistDataUri = `data:application/dash+xml;base64,${btoa(playlist)}`;

      loadPromise = this.player.load(
        playlistDataUri,
        0,
        'application/dash+xml'
      );
    }

    loadPromise
      .then(() => {
        this.videoElement?.play();
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  ngOnDestroy() {
    this.player.destroy();
  }
}
