import { Component, OnInit, OnDestroy, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Web3ContentSpec } from 'src/app/models/models.types';

@Component({
  selector: 'app-content-nft-player',
  templateUrl: './content-nft-player.component.html',
  styleUrls: ['./content-nft-player.component.scss'],
})
export class ContentNftPlayerComponent implements OnInit, OnDestroy {
  oldViewportValue!: string;
  contentSpec: Web3ContentSpec;
  specInfo: any;

  @ViewChild('swiperRef', { static: false })
  private swiperRef: any;

  @ViewChild('swiperPagination', { static: false })
  private swiperPaginationRef: any;

  get files(): any[] {
    return this.specInfo.files;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: { contentSpec: Web3ContentSpec; specInfo: any },
  ) {
    this.contentSpec = data.contentSpec;
    this.specInfo = data.specInfo;
  }

  ngOnInit(): void {
    const viewportMeta = document.querySelector('meta[name="viewport"]')!;
    this.oldViewportValue = viewportMeta.getAttribute('content')!;
    viewportMeta.setAttribute(
      'content',
      'width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1'
    );
  }

  ngAfterViewInit() {
    if (this.files.length >= 2) {
      const swiperEl = this.swiperRef.nativeElement;
      Object.assign(swiperEl, {
        allowTouchMove: false,
        observer: true,
        navigation: true,
        pagination: {
          el: this.swiperPaginationRef.nativeElement,
          clickable: true,
        },
        on: {
          slideChange(swiper: any) {
            const newIndex = swiper.activeIndex;
            document.querySelectorAll(`swiper-slide:not([data-index="${newIndex}"])`).forEach((slideEl) => {
              const mediaEl = slideEl.querySelector('video, audio') as HTMLMediaElement;
              mediaEl?.pause();
            });
          },
        }
      });
      swiperEl.initialize();
    }
  }

  ngOnDestroy(): void {
    const viewportMeta = document.querySelector('meta[name="viewport"]')!;
    viewportMeta.setAttribute('content', this.oldViewportValue);
  }
}
