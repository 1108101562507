<app-header></app-header>

<!-- main content -->
<div class="page-container">
  <!-- back button -->
  <section class="back-button-container">
    <div class="back-btn" (click)="back()">
      <mat-icon>navigate_before</mat-icon>
      <div>Back</div>
    </div>
  </section>

  <p class="title">Select Content type</p>

  <div class="types">
    <div class="type" (click)="selectContentType('card')">
      <div>
        <mat-icon>image</mat-icon>
        <span>Card</span>
      </div>
      <p>xxxxxxxx</p>
    </div>
    <div class="type" (click)="selectContentType('book')">
      <div>
        <mat-icon>book</mat-icon>
        <span>Book</span>
      </div>
      <p>xxxxxxxx</p>
    </div>
    <div class="type" (click)="selectContentType('music')">
      <div>
        <mat-icon>library_music</mat-icon>
        <span>Music</span>
      </div>
      <p>xxxxxxxx</p>
    </div>
    <div class="type" (click)="selectContentType('movie')">
      <div>
        <mat-icon>video_library</mat-icon>
        <span>Movie</span>
      </div>
      <p>xxxxxxxx</p>
    </div>
  </div>
</div>
