<section class="column">
  <div style="position: relative; width: 100%;">
  <mat-icon (click)="close()" svgIcon="xmark-solid" class="icon-close"></mat-icon>
</div>  
  <div class="column message">
    <div class="flex-row">
      <h4 style="color: var(--main-black);">Add existing Brand</h4>
    </div>
    <div>
      <p>
        If you have a smart contract address, you can add it as a Brand.
      </p>
      <mat-form-field class="example-full-width" style="width: 100%;" appearance="fill">
        <mat-label>Smart contract address</mat-label>
        <input matInput [formControl]="inputAddress" />
      </mat-form-field>
      <!--
      <p>
        Brand name is a name that applies only within your wallet.
      </p>
      -->
      <mat-form-field class="example-full-width" style="width: 100%;" appearance="fill">
        <mat-label>Brand name</mat-label>
        <input matInput [formControl]="inputName" placeholder="Max 20 characters" maxlength="20" />
      </mat-form-field>
      <div class="flex-row" style="justify-content: center; margin-top: 20px;">
        <button class="primary-btn" (click)="done()" [disabled]="invalid()">
          Add
        </button>
      </div>
    </div>
  </div>
</section>