<div class="footer-wrapper" *ngIf="visible" style="background-image: url('assets/image/bg.png');">
  <section class="footer-container">
    <div>
      <div>
        <img
          src="assets/image/nft.png"
          class="svg-icon"
          (click)="goNft()"
          [ngClass]="{ active: !isFtMode() }"
        >
      </div>
      <div>
        <img
          src="assets/image/spt.png"
          class="svg-icon"
          (click)="goSpt()"
          [ngClass]="{ 'active': isFtMode() }"
        >
      </div>
    </div>
  </section>
</div>
