<!-- アドレス選択 -->
<section class="address-book-container" *ngIf="mode === 1">
  <div class="flex-row" style="justify-content: space-between;">
    <h4>Select address</h4>
    <button type="button" class="primary-btn-short button-add" (click)="toAdd()">
      Add address
    </button>
  </div>
  <ng-container *ngIf="addressBookList.length; else noAddress">
    <ul>
      <ng-container *ngFor="let addressBook of addressBookList">
        <li (click)="done(addressBook.address)" class="under-line">
          <div>
            <p class="address-book-name">Name:</p>
            <p class="address-book-address">{{ addressBook.name }}</p>  
          </div>
          <div>
            <p class="address-book-address" style="width: 100%;">{{ addressBook.address }}</p>
          </div>
        </li>
      </ng-container>
    </ul>
  </ng-container>
  <ng-template #noAddress>
    No address found.
  </ng-template>
</section>

<!-- アドレス追加 -->
<section class="address-book-container" *ngIf="mode === 2">
  <div class="flex-row" style="justify-content: space-between;">
    <h4>Add address</h4>
  </div>
  <form [formGroup]="form">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput id="name" type="text" autocomplete="off" formControlName="name" placeholder="Max 25 alphanumeric characters" maxlength="25"/>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Address</mat-label>
      <input matInput id="address" type="text" autocomplete="off" formControlName="address" placeholder="address" />
    </mat-form-field>
    <div style="text-align: center;">
      <button type="button" class="primary-btn-short button-add" (click)="add()" [disabled]="!validate()">
        Add
      </button>
    </div>
  </form>

</section>