/**
 * @dev 画像ファイルの取得用の実装
 * コントラクトごとに設定が必要な場合はここに実装を行う
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PublicStorageService {
  constructor(private http: HttpClient) { }

  /**
   * ファイルダウンロード
   * @param token チャレンジデータを秘密鍵で署名したデータ
   * @param cid 暗号化されたファイルのCID
   * @param address ウォレットアドレス
   * @returns ファイル
   */
  async fileDownload(token: string, cid: string, address: string, contractAddress: string, specId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'x-sanpo-address': address,
        'x-sanpo-contract': contractAddress,
        'x-sanpo-specid': specId,
      })
    };

    return await firstValueFrom(
      this.http.get(
        `${environment.ipfsGwUrl}/v2/files/${cid}`,
        {
          headers: httpOptions.headers,
          responseType: 'text',
        },
      )
    );
  }

  /**
   * ファイルアップロード
   * @param token チャレンジデータを秘密鍵で署名したデータ
   * @param file ファイルデータ
   * @param address ウォレットアドレス
   * @param contractAddress コントラクトアドレス
   * @returns 暗号化されたファイルのCID
   */
  async fileUpload(token: string, file: File, filename: string,  address: string, contractAddress: string) {
    return await new Promise<{ cid: string; jobId?: string } | { error: string }>(
      (resolve) => {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = async (e) => {
          const base64 = e.target?.result;
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'x-sanpo-address': address,
              'x-sanpo-contract': contractAddress,
            }),
          };
          httpOptions.headers.set('address', address);
          const body = { file: base64, filename };
          try {
            const cidRes = await firstValueFrom(
              this.http.post<any>(
                `${environment.ipfsGwUrl}/v2/files`,
                body,
                httpOptions
              )
            );
            if (cidRes.hasOwnProperty('error')) {
              resolve({ error: cidRes.error });
            } else {
              resolve({ jobId: cidRes.jobId, cid: cidRes.cid });
            }
          } catch (error) {
            resolve({ error: '' });
          }
        };
      }
    );
  }

  // videoFileアップロード
  async fileUploadEpub(token: string, files: File[], address: string, contractAddress: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'x-sanpo-address': address,
        'x-sanpo-contract': contractAddress,
      }),
    };
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    return await firstValueFrom(
      this.http.post<any>(
        `${environment.ipfsGwUrl}/v2/files/epub`,
        formData,
        httpOptions
      )
    );
  }

  // DRM適用ジョブのステータス確認
  async checkForDRMStatus(token: string, address: string, jobId: string, _contractAddress: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-sanpo-address': address,
        'x-sanpo-contract': _contractAddress,
      }),
    };
    return firstValueFrom(
      this.http.get<{ status: true; cids: { widevine: string } } | { status: false; cids: null }>(
        `${environment.ipfsGwUrl}/v2/files/jobs/${jobId}`,
        httpOptions,
      ),
    );
  }

  // design
  async design(token: string, address: string, serializedTx: any, _contractAddress: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-sanpo-address': address,
        'x-sanpo-contract': _contractAddress,
      }),
    };
    return await firstValueFrom(
      this.http.post<any>(
        `${environment.ipfsGwUrl}/v2/transactions/design`,
        { serializedTx },
        httpOptions,
      )
    );
  }

  // mint
  async mint(token: string, address: string, serializedTx: any, _contractAddress: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-sanpo-address': address,
        'x-sanpo-contract': _contractAddress,
      }),
    };
    return await firstValueFrom(
      this.http.post<any>(
        `${environment.ipfsGwUrl}/v2/transactions/mint`,
        { serializedTx },
        httpOptions,
      )
    );
  }

  async getBytecode(token: string, address: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-sanpo-address': address,
      }),
    };
    return await firstValueFrom(
      this.http.get<any>(
        `${environment.ipfsGwUrl}/v2/contracts/content-nft/bytecode-v4`,
        httpOptions,
      )
    );
  }


  // begin challenge
  async challenge(address: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-sanpo-address': address,
      }),
    };
    return await firstValueFrom(
      this.http.post<any>(
        `${environment.authGwUrl}/begin`,
        { account: address },
        httpOptions,
      )
    );
  }

  // auth
  async auth(address: string, id: string, sign: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-sanpo-address': address,
      }),
    };
    return await firstValueFrom(
      this.http.post<any>(
        environment.authGwUrl,
        { id, sign },
        httpOptions,
      )
    );
  }
}
