import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { PasswordDialogComponent } from './components/password-dialog/password-dialog.component';
import { MintDialogComponent } from './components/mint-dialog/mint-dialog.component';
import { AddBrandDialogComponent } from './components/add-brand-dialog/add-brand-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { SelectAddressDialogComponent } from './components/select-address-dialog/select-address-dialog.component';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { ShakaPlayerComponent } from './components/shaka-player/shaka-player.component';
import { MatChipsModule } from '@angular/material/chips';
import { ContentNftPlayerComponent } from './components/content-nft-player/content-nft-player.component';
import { ContentNftPlayerRendererComponent } from './components/content-nft-player-renderer/content-nft-player-renderer.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    PasswordDialogComponent,
    MintDialogComponent,
    AddBrandDialogComponent,
    SelectAddressDialogComponent,
    InformationDialogComponent,
    ShakaPlayerComponent,
    ContentNftPlayerComponent,
    ContentNftPlayerRendererComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatFormFieldModule,
    MatTableModule,
    MatMenuModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    ConfirmDialogComponent,
    PasswordDialogComponent,
    MintDialogComponent,
    AddBrandDialogComponent,
    ShakaPlayerComponent,
    MatButtonModule,
    MatChipsModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTableModule,
    MatSnackBarModule,
    MatDividerModule,
    MatMenuModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
