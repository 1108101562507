import { Component, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { AddressBook } from 'src/app/models/models.types';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.component';
import { KeyService } from 'src/app/services/key/key.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { Web3Service } from 'src/app/services/web3/web3.service';
@Component({
  selector: 'app-select-address-dialog',
  templateUrl: './select-address-dialog.component.html',
  styleUrls: ['./select-address-dialog.component.scss']
})
export class SelectAddressDialogComponent implements OnInit {
  form: FormGroup = this.fb.group({
    address: ['', []],
    name: ['', []],
  });
  addressBookList: AddressBook[] = [];
  mode = 1; // 1: select, 2: add

  constructor(
    private dialogRef: MatDialogRef<
      SelectAddressDialogComponent,
      string | undefined
    >,
    private fb: FormBuilder,
    private confirmDialogService: ConfirmDialogService,
    private keyService: KeyService,
    private storageService: StorageService,
    private web3Service: Web3Service,
  ) { }

  async ngOnInit(): Promise<void> {
    const encryptedEmail = await this.storageService.getEmailAddress() ?? '';
    this.addressBookList = (await this.keyService.getDecryptedAddressBookList(encryptedEmail))
      .sort((a, b) => a.name.localeCompare(b.name));
  }
  async select(): Promise<void> {
    this.dialogRef.close();
  }

  async done(response: string): Promise<void> {
    this.dialogRef.close(response);
  }

  toAdd() {
    this.mode = 2;
  }

  validate() {
    // 単項目チェック
    const address = this.form.getRawValue().address;
    const name = this.form.getRawValue().name;
    if (address.length === 0 || name.length === 0) {
      return false;
    }
    return this.web3Service.isAddress(address);
  }

  async add() {
    const address = this.form.getRawValue().address;
    const name = this.form.getRawValue().name;

    // 登録済みチェック
    const newAddressBook: AddressBook = { name, address };
    const encryptedEmail = await this.storageService.getEmailAddress() ?? '';
    const addressBookList = await this.keyService.getDecryptedAddressBookList(encryptedEmail) || [];
    const registerd = addressBookList
      .find((address) => address.address === newAddressBook.address || address.name === newAddressBook.name);
    if (!!registerd) {
      this.confirmDialogService.openComplete("Address or Name is already registerd.");
      return;
    }

    // アドレスリストに登録
    await this.keyService.setDecryptedAddressBookList(
      encryptedEmail,
      [...addressBookList, newAddressBook]
    );

    // formの値をクリア
    this.form.patchValue({
      address: '',
      name: '',
    });

    // アドレス選択に戻る
    this.mode = 1;
    this.ngOnInit();
  }
}
@Injectable({
  providedIn: 'root',
})
export class SelectAddressDialogService {
  constructor(private dialog: MatDialog) { }
  /** dialog open */
  async open(): Promise<string | undefined> {
    const ref = this.dialog.open(SelectAddressDialogComponent, {
      width: '90%',
      maxWidth: '390px',
      height: '320px'
    });
    const res = await firstValueFrom(ref.afterClosed());
    return res;
  }
}
