import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CryptService } from 'src/app/services/crypt/crypt.service';
import { PublicStorageService } from 'src/app/services/public-storage/public-storage.service';
import { KeyService } from 'src/app/services/key/key.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ConfirmDialogService } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { PasswordDialogService } from 'src/app/shared/components/password-dialog/password-dialog.component';
import { Web3Service } from 'src/app/services/web3/web3.service';
import { pairwise } from 'rxjs';
import { Location } from '@angular/common';
import * as pica from 'pica';

type FileInfo = {
  file: File;
  type: string;
  src: string | ArrayBuffer;
}

@Component({
  selector: 'app-content-nft-mint',
  templateUrl: './content-nft-mint.component.html',
  styleUrls: ['./content-nft-mint.component.scss'],
})
export class ContentNftMintComponent implements OnInit {
  contractName = '';
  walletAddress = '';
  email = '';
  productImgFile: File | null = null; // 選択されたプロダクトイメージ画像ファイル
  fileInfos: FileInfo[] = []; // ファイルプレビュー表示用
  productImgSrc: string | ArrayBuffer = ''; // プロダクトイメージ画像表示用
  contractDoc = 'The user who owns this NFT may display the image on the Sanpō Wallet screen during the period of ownership and possession. In the event of succession, transfer, or other transfer of this NFT to another party, the user may not display the image thereafter. The use of such images by the User is limited to the above. For example, you may not modify the image, create a derivative work of the image, or display the image on any other website or social networking service. All copyrights (including rights under Articles 27 and 28 of the Copyright Act), trademarks, design rights, and other intellectual property rights related to such images belong to the Company or its designees. Possession of this NFT does not transfer them to the user.';
  contentType!: string;
  genres: any[] = [];

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  @ViewChild('productImageInput', { static: false }) productImageInput!: ElementRef;

  form = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required, Validators.maxLength(30)],
    }),
    description: new FormControl('', {
      validators: [Validators.required, Validators.maxLength(80)],
    }),
    contractDoc: new FormControl('', {
      validators: [
        Validators.required,
        Validators.maxLength(1000),
      ],
    }),
    totalSupplyLimit: new FormControl('', {
      validators: [
        Validators.required,
        Validators.min(1),
        Validators.max(100),
        Validators.pattern('^([1-9][0-9]*)$'),
      ],
    }),
    allowSecondaryMerket: new FormControl('1', {
      validators: [
        Validators.required
      ],
    }),
    // TODO: genre, genreAttributes を追加する
    contentCopy: new FormControl('0', {
      validators: [
        Validators.required
      ],
    }),
    personalInfo: new FormControl('1', {
      validators: [
        Validators.required
      ],
    }),
    royalty: new FormControl('', {
      validators: [
        Validators.min(0),
        Validators.max(99),
        Validators.pattern('^([1-9][0-9]*|0)$'),
      ],
    }),
  });

  constructor(
    private router: Router,
    private storageService: StorageService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private keyService: KeyService,
    private _clipboardService: ClipboardService,
    private _snackBar: MatSnackBar,
    private passwordDialog: PasswordDialogService,
    private confirmDialog: ConfirmDialogService,
    private cryptService: CryptService,
    private publicStorageService: PublicStorageService,
    private web3Service: Web3Service,
    private location: Location,
  ) { }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    this.walletAddress = (await this.storageService.getWalletAddress()) ?? '';
    this.email = await this.keyService.getDecryptEmailAddress();
    this.contractName = await this.storageService.getContractName() || '';
    this.contentType = await this.storageService.getContentType() || 'card';
    this.genres = [
      {
        name: 'Anime',
        attributes: ['Anime Attribute 1'],
      },
      {
        name: 'Comics',
        attributes: ['Comics Attribute 1', 'Comics Attribute 2'],
      },
    ];

    this.form.valueChanges.pipe(pairwise()).subscribe(([prev, current]) => {
      if (prev.personalInfo === '1' && current.personalInfo === '0') {
        this.form.patchValue({
          contentCopy: '1',
        });
      }
    });

    this.spinner.hide();
  }

  onChangeFileInput(event: any, index?: number) {
    const reader = new FileReader();

    const isProductImage = index === undefined;
    if (isProductImage) {
      if (event.target.files.length === 0) {
        this.productImgSrc = '';
        this.productImgFile = null;
        this.productImageInput.nativeElement.value = null;
        return;
      }

      // プロダクトイメージ画像
      this.productImgFile = event.target.files[0];
      if (event.target.files[0].size > 1 * 1024 * 1024) {
        this.productImgSrc = '';
        this.productImgFile = null;
        this.productImageInput.nativeElement.value = null;
        this.openSnackBar('Please select images under 1MB');
        return;
      }
      reader.onload = () => {
        if (reader.result !== null) {
          this.productImgSrc = reader.result;
        }
      };
      reader.readAsDataURL(event.target.files[0]);
      return;
    }

    //fileが選択されていなければリセット
    if (event.target.files.length === 0) {
      this.fileInfos.splice(index, 1);
      this.fileInput.nativeElement.value = null;
      return;
    }
    const filename = event.target.files[0].name;
    let type!: string;
    if (
      filename.indexOf('.png') > 0 ||
      filename.indexOf('.jpg') > 0 ||
      filename.indexOf('.jpeg') > 0
    ) {
      type = 'card';
    } else if (filename.indexOf('.mp4') > 0) {
      type = 'movie';
    } else if (filename.indexOf('.mp3') > 0) {
      type = 'music';
    } else {
      this.openSnackBar('Only png, jpg, jpeg, mp3, mp4 files are available');
      return;
    }

    //ファイルの情報をimgSrcに保存
    if (event.target.files[0].size > 100 * 1024 * 1024) {
      this.fileInfos.splice(index, 1);
      this.fileInput.nativeElement.value = null;
      this.openSnackBar('Please select files under 100MB');
      return;
    }

    reader.onload = () => {
      if (reader.result === null) {
        this.fileInfos.splice(index, 1);
        return;
      }

      const fileInfo = {
        file: event.target.files[0],
        type: type,
        src: reader.result,
      };
      if (this.fileInfos.length >= index) {
        this.fileInfos[index] = fileInfo;
      } else {
        this.fileInfos.push(fileInfo);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  async onSubmit(mintAll: boolean) {
    if (
      this.form.getRawValue().allowSecondaryMerket == 0 &&
      !Number.isInteger(this.form.getRawValue().royalty)
    ) {
      this.openSnackBar('Enter royalty between 0 and 99');
      return;
    }

    const password = await this.passwordDialog.open();
    if (!password) {
      return;
    }
    const address = this.walletAddress;
    const privateKey = (await this.storageService.getPrivateKey()) ?? '';
    //復号鍵はここでのみ使用する
    const decryptedPrivateKey = this.cryptService.decryption(
      privateKey,
      password
    );
    const checkPrivateKeyToAddress =
      await this.keyService.checkPrivateKeyToAddress(
        this.walletAddress,
        decryptedPrivateKey
      );
    if (!checkPrivateKeyToAddress) {
      this.confirmDialog.openComplete('Password is invalid');
      return;
    }
    const res = await this.confirmDialog.openConfirm(
      'Are you sure you want to create?'
    );
    let cid!: string;
    let productImgCid!: string;
    if (res) {
      this.spinner.show();
      let token: any;
      const contractAddress = await this.storageService.getContractAddress() || '';
      if (this.fileInfos.length > 0) {
        // チャレンジデータ取得
        const challenge = await this.publicStorageService.challenge(this.walletAddress);
        if ('error' in challenge) {
          this.openSnackBar(challenge.error);
          this.spinner.hide();
          return;
        }

        // 署名
        const signedChallenge = await this.web3Service.sign(decryptedPrivateKey, challenge.message);
        const auth = await this.publicStorageService.auth(
          this.walletAddress,
          challenge.id,
          signedChallenge,
        );
        console.log("DEBUG auth", auth);
        if (auth.hasOwnProperty('error')) {
          this.openSnackBar(auth.error);
          this.spinner.hide();
          return;
        }

        token = auth.token;

        if (this.contentType === 'card') {
          const resizedFile = await this.resizeImage(this.fileInfos[0].file);
          if (resizedFile) {
            const productImgRes = await this.publicStorageService.fileUpload(
              token,
              resizedFile,
              resizedFile.name,
              address,
              contractAddress,
            );
            if ('error' in productImgRes) {
              this.spinner.hide();
              this.openSnackBar(`Image upload failed. ${productImgRes.error}`);
              return;
            }
            productImgCid = productImgRes.cid;
          }
        } else if (this.contentType === 'book') {
          const productImgRes = await this.publicStorageService.fileUpload(
            token,
            this.productImgFile!,
            this.productImgFile!.name,
            address,
            contractAddress,
          );
          const fileUploadRes = await this.publicStorageService.fileUploadEpub(
            token,
            this.fileInfos.map((fileInfo) => fileInfo.file),
            address,
            contractAddress,
          );
          if ('error' in productImgRes) {
            this.spinner.hide();
            this.openSnackBar(`Image upload failed. ${productImgRes.error}`);
            return;
          }
          productImgCid = productImgRes.cid;
          cid = fileUploadRes.cid;
        } else {
          const productImgRes = await this.publicStorageService.fileUpload(
            token,
            this.productImgFile!,
            this.productImgFile!.name,
            address,
            contractAddress,
          );
          const fileUploadRes = await this.publicStorageService.fileUploadEpub(
            token,
            this.fileInfos.map((fileInfo) => fileInfo.file),
            address,
            contractAddress,
          );
          if ('error' in productImgRes) {
            this.spinner.hide();
            this.openSnackBar(`Image upload failed. ${productImgRes.error}`);
            return;
          }
          productImgCid = productImgRes.cid;
          cid = fileUploadRes.cid;
        }
      } else {
        this.spinner.hide();
        this.openSnackBar('Please Choose File');
        return;
      }
      const name = this.form.getRawValue().name;
      const description = this.form.getRawValue().description;
      const totalSupplyLimit = this.form.getRawValue().totalSupplyLimit;
      const contractDoc = this.form.getRawValue().contractDoc;
      const allowSecondaryMerket = this.form.getRawValue().allowSecondaryMerket;
      const contentCopy = this.form.getRawValue().contentCopy;
      const personalInfo = this.form.getRawValue().personalInfo;
      const royalty = this.form.getRawValue().royalty;
      if (!name || !description || !totalSupplyLimit) {
        this.spinner.hide();
        this.openSnackBar('Please complete all entry forms');
        return;
      }

      const info = {
        name: name,
        description: description,
        quantity: totalSupplyLimit,
      };
      const tuple = <T extends any[]>(...args: T): T => args;
      const designData = tuple(
        this.walletAddress, // address
        decryptedPrivateKey, // privateKey
        name,
        "", // _symbol,
        this.contentType, // _contentType,
        cid, // _mediaId,
        productImgCid, // _thumbnailId
        totalSupplyLimit, // _totalSupplyLimit,
        [JSON.stringify(info)], // _info,
        [contractDoc], // _agreements,
        contentCopy === '1',
        personalInfo === '1',
        allowSecondaryMerket == 0 ? true : false, // _secondarySales
        allowSecondaryMerket == 0 ? [royalty] : [0], // _royalty,
        false,
        'beta1',
      );

      // Designのトランザクションデータ生成
      const serializedTx = await this.web3Service.createDesignTx(...designData);
      const res = await this.publicStorageService.design(
        token,
        this.walletAddress,
        serializedTx,
        contractAddress,
      );

      // Mintのトランザクションデータ作成
      const mintInfo = {
        issuer: this.walletAddress
      };

      const mintTxList = [];
      let nonce: any = await this.web3Service.getTransactionCount(this.walletAddress);
      const num = mintAll ? totalSupplyLimit : 1;
      for (let i = 0; i < num; i++) {
        mintTxList.push(this.web3Service.createMintTx(
          this.walletAddress, // address
          decryptedPrivateKey, // privateKey
          this.walletAddress, // _to
          res.DesignLog._specId, // _specId
          cid, // _mediaId
          [JSON.stringify(mintInfo)], // _info
          'beta1',
          nonce++, // nonce
        ));
      }
      const mintRes = await this.publicStorageService.mint(
        token,
        this.walletAddress,
        mintTxList,
        contractAddress,
      )
      this.spinner.hide();
      await this.confirmDialog.openComplete('Done!');
      this.backToList();
    }
  }
  async logout() {
    await this.authService.logOut();
  }
  back() {
    this.location.back();
  }
  backToList() {
    this.router.navigate(['/content-nft-list']);
  }
  // ウォレットアドレスをコピー
  copyWalletAddress() {
    const decryptKey = this.walletAddress;
    this._clipboardService.copy(decryptKey);
    // snackBar表示
    this.openSnackBar('Copied your wallet address.');
  }
  // スナックバー
  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 4000, // 4s
      panelClass: ['brown-snackbar'],
      verticalPosition: 'bottom',
    });
  }

  // <input type="file"> で許可するファイルタイプ
  get acceptFileType(): string {
    switch (this.contentType) {
      case 'card':
        return 'image/*';
      case 'book':
        return 'image/*,video/mp4,audio/mp3';
      case 'movie':
        return 'video/mp4';
      case 'music':
        return 'audio/mp3';
    }

    return '';
  }

  private async resizeImage(file: File) {
    // 画像のサイズを取得する
    const image = new Image();
    image.src = URL.createObjectURL(file);
    const [width, height] = await new Promise<[number, number]>((resolve) => {
      image.onload = () => {
        resolve([image.width, image.height]);
      };
    });

    // リサイズ後のサイズを決定する
    // 一辺の最大サイズを 1200px とする
    const maxSize = 1200;
    if (width <= maxSize && height <= maxSize) {
      // 両辺が最大サイズを下回っている場合、リサイズを行わない
      return null;
    }

    // リサイズ後のサイズを計算する
    const ratio = width / height;
    const resizedWidth = ratio > 1 ? maxSize : maxSize * ratio;
    const resizedHeight = ratio > 1 ? maxSize / ratio : maxSize;

    // リサイズを適用する
    const canvas = document.createElement('canvas');
    canvas.width = resizedWidth;
    canvas.height = resizedHeight;

    const result = await pica().resize(image, canvas);
    return new Promise<File>((resolve) => {
      result.toBlob((blob) => {
        const resizedFile = new File([blob!], file.name, {
          type: file.type,
        });
        resolve(resizedFile);
      }, file.type);
    });
  }
}
