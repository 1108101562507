<ngx-spinner type="square-jelly-box" size="medium" [fullScreen]="true">
  <p style="color: white">Processing...</p>
</ngx-spinner>

<!-- header -->
<app-header></app-header>

<!-- main content -->
<div class="page-container">
  <!-- back button -->
  <section class="back-button-container">
    <div class="back-btn" (click)="back()">
      <mat-icon>navigate_before</mat-icon>
      <div>Back</div>
    </div>
  </section>
  <div class="input-wrapper">
    <div class="radio-input-wrapper">
      <p>Create a NFT Brand</p>
    </div>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Brand Name</mat-label>
      <input matInput [formControl]="brandName" placeholder="Max 20 characters" maxlength="20"/>
    </mat-form-field>
    <p>You can create NFTs under this Brand.</p>
    <section class="btn-container">
      <button type="button" class="primary-btn" (click)="deployContract()" [disabled]="!formForDeployIsValid">
        Create
      </button>
    </section>
  </div>
</div>