import { importExpr } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-wallet',
  templateUrl: './select-wallet.component.html',
  styleUrls: ['./select-wallet.component.scss'],
})
export class SelectWalletComponent {
  constructor(private router: Router) { }

  navigateLogin() {
    this.router.navigate(['/login-by-password']);
  }
  
  navigateCreate() {
    this.router.navigate(['/create-wallet']);
  }

  navigateImport() {
    this.router.navigate(['/create-by-secret-key']);
  }
}
