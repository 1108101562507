import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentNftListComponent } from './features/content-nft-list/content-nft-list.component';
import { CreateWalletComponent } from './features/create-wallet/create-wallet.component';
import { LaunchComponent } from './features/launch/launch.component';
import { SelectWalletComponent } from './features/select-wallet/select-wallet.component';
import { ContentNftDetailComponent } from './features/content-nft-detail/content-nft-detail.component';
import { CreateBySecretKeyComponent } from './features/create-by-secret-key/create-by-secret-key.component';
import { ContentNftSendResultComponent } from './features/content-nft-send-result/content-nft-send-result.component';
import { AuthGuard } from './guard/auth.guard';
import { UnauthGuard } from './guard/unauth.guard';
import { CreateWalletSaveComponent } from './features/create-wallet-save/create-wallet-save.component';
import { LoginByPasswordComponent } from './features/login-by-password/login-by-password.component';
import { Erc20BalanceComponent } from './features/erc20-balance/erc20-balance.component';
import { Erc20TransferComponent } from './features/erc20-transfer/erc20-transfer.component';
import { Erc20TransferConfirmComponent } from './features/erc20-transfer-confirm/erc20-transfer-confirm.component';
import { ContentNftMintComponent } from './features/content-nft-mint/content-nft-mint.component';
import { NftMenuComponent } from './features/nft-menu/nft-menu.component';
import { CreateBrandComponent } from './features/create-brand/create-brand.component';
import { createBrotliCompress } from 'zlib';
import { ContentTypeSelectComponent } from './features/content-type-select/content-type-select.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LaunchComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'select-wallet',
    pathMatch: 'full',
    component: SelectWalletComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'create-by-secret-key',
    pathMatch: 'full',
    component: CreateBySecretKeyComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'create-wallet',
    pathMatch: 'full',
    component: CreateWalletComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'create-wallet-save',
    pathMatch: 'full',
    component: CreateWalletSaveComponent,
  },
  {
    path: 'login-by-password',
    pathMatch: 'full',
    component: LoginByPasswordComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'content-nft-list',
    pathMatch: 'full',
    component: ContentNftListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'content-nft-select-type',
    pathMatch: 'full',
    component: ContentTypeSelectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'content-nft-mint',
    pathMatch: 'full',
    component: ContentNftMintComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'content-nft-detail/:specId',
    pathMatch: 'full',
    component: ContentNftDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'content-nft-send-result',
    pathMatch: 'full',
    component: ContentNftSendResultComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'spt-balance',
    pathMatch: 'full',
    component: Erc20BalanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'spt-transfer',
    pathMatch: 'full',
    component: Erc20TransferComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'spt-transfer-confirm',
    pathMatch: 'full',
    component: Erc20TransferConfirmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nft-menu',
    pathMatch: 'full',
    component: NftMenuComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-brand',
    pathMatch: 'full',
    component: CreateBrandComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
