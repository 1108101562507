<!-- header -->
<app-header></app-header>

<!-- main content -->
<div class="page-container">
  <!-- back button -->
  <section class="back-button-container">
    <div class="back-btn" routerLink="/content-nft-list">
      <mat-icon>navigate_before</mat-icon>
      <div>Back</div>
    </div>
  </section>

  <div class="main-container">
    <h2>Sent a NFT.</h2>
    <p style="color: black;">Transaction Hash.</p>
    <p>{{ transactionNo }}</p>
  </div>
  <section class="btn-container">
    <button class="primary-btn" routerLink="/content-nft-list">
      Back to My Gallery
    </button>
  </section>
</div>