import { Component, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-add-brand-dialog',
  templateUrl: './add-brand-dialog.component.html',
  styleUrls: ['./add-brand-dialog.component.scss'],
})
export class AddBrandDialogComponent {
  inputAddress = new FormControl(null);
  inputName = new FormControl(null);

  constructor(
    private dialogRef: MatDialogRef<
    AddBrandDialogComponent,
      string | undefined
    >,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      'xmark-solid',
      sanitizer.bypassSecurityTrustResourceUrl('assets/image/xmark-solid.svg'));
  }

  done(): void {
    this.dialogRef.close(JSON.stringify({
      name: this.inputName.value,
      address: this.inputAddress.value,
    }));
  }

  close(): void {
    this.dialogRef.close();
  }

  invalid() {
    return this.inputName.value && this.inputAddress.value ? false : true;
  }
}
@Injectable({
  providedIn: 'root',
})
export class AddBrandDialogService {
  constructor(private dialog: MatDialog) {}
  /** dialog open */
  async open(): Promise<string | undefined> {
    const ref = this.dialog.open(AddBrandDialogComponent, {
      width: '90%',
      maxWidth: '390px',
      height: '450px',
    });
    const res = await firstValueFrom(ref.afterClosed());
    return res;
  }
}
