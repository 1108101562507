<section class="column">
  <div class="column message">
    <p style="display: block; width: 100%; text-align: left">
      Sanpō Wallet β
    </p>
  </div>
  <div class="space btn-box">
    <button class="primary-btn" (click)="close()">
      OK
    </button>
  </div>
</section>
