<ngx-spinner type="square-jelly-box" size="medium" [fullScreen]="true">
  <p style="color: white">Processing...</p>
</ngx-spinner>
<!-- header -->
<app-header></app-header>

<!-- main content -->
<div class="page-container">
  <div class="card-wrapper" *ngIf="balances$ | async as balanceOf">
    <div class="card-container card-balance">
      <div style="width: calc(100% - 40px); height: 100%; justify-content: space-between;">
        <h3 style="margin-top: unset;">Account Balance</h3>
        <div class="text-balance" style="text-align: end;">
          <span style="font-size: 26px;">{{ balanceOf[0]?.balance | toBaseUnit | number }}</span>
          <span> {{ erc20Symbol?.symbol ? erc20Symbol?.symbol : "SPT" }}</span>
        </div>
      </div>
    </div>
    <div class="flex-row" style="margin-top: 100px;">
      <div class="flex-row" style="width: calc(100% - 40px); cursor: pointer;  justify-content: flex-start;"
      (click)="goTransfer()">
        <mat-icon svgIcon="circle-arrow-right-solid" class="icon-transfer"></mat-icon>
        <h4 style="text-decoration: underline;">Transfer to others</h4>
      </div>
    </div>
  </div>
  <section class="main-container" *ngIf="isError">
    <div class="list-title">{{ serviceName }}</div>
    <ul>
      <ng-container>
        <li>Error occurred</li>
      </ng-container>
    </ul>
  </section>
</div>
