import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PublicStorageService } from 'src/app/services/public-storage/public-storage.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-content-nft-player-renderer',
  templateUrl: './content-nft-player-renderer.component.html',
  styleUrls: ['./content-nft-player-renderer.component.scss'],
})
export class ContentNftPlayerRendererComponent implements OnInit {
  @Input()
  spec!: any;

  @Input()
  content!: any;

  mediaSource!: string;
  thumbnailSource!: string;
  drmMediaSource!: any;

  @ViewChild('audioRef', { static: false })
  private audioRef: any;

  constructor(
    private sanitization: DomSanitizer,
    private storageService: StorageService,
    private publicStorageService: PublicStorageService,
  ) {}

  async ngOnInit() {
    if (typeof this.content.cid === 'object') {
      this.drmMediaSource = this.content.cid;
      return;
    }

    const walletAddress = (await this.storageService.getWalletAddress()) ?? '';
    const cAddress = (await this.storageService.getContractAddress()) || '';
    const mediaFile = await this.publicStorageService.fileDownload(
      'dummy_token',
      this.convertCid(this.content.cid),
      walletAddress,
      cAddress,
      this.spec.specId
    );
    if (this.spec.thumbnailId &&  this.content.type === 'music') {
      const thumbnailFile = await this.publicStorageService.fileDownload(
        'dummy_token',
        this.convertCid(this.spec.thumbnailId),
        walletAddress,
        cAddress,
        this.spec.specId
      );
      this.thumbnailSource = thumbnailFile;
    }
    this.mediaSource = this.sanitization.bypassSecurityTrustUrl(mediaFile) as string;
  }

  private convertCid(cid: string) {
    if (cid.startsWith('https')) {
      const urlArray = cid.split("/");
      return urlArray[urlArray.length - 1];
    }
    return cid;
  }

  toggleAudio() {
    const audioEl = this.audioRef.nativeElement;
    if (audioEl.paused) {
      audioEl.play();
    } else {
      audioEl.pause();
    }
  }
}
