import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LaunchComponent } from './features/launch/launch.component';
import { SelectWalletComponent } from './features/select-wallet/select-wallet.component';
import { CreateWalletComponent } from './features/create-wallet/create-wallet.component';
import { SharedModule } from './shared/shared.module';
import { ToBaseUnitPipe } from './shared/pipes/to-base-unit.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContentNftListComponent } from './features/content-nft-list/content-nft-list.component';
import { ContentNftDetailComponent } from './features/content-nft-detail/content-nft-detail.component';
import { CreateBySecretKeyComponent } from './features/create-by-secret-key/create-by-secret-key.component';
import { ContentNftSendResultComponent } from './features/content-nft-send-result/content-nft-send-result.component';
import { LoginByPasswordComponent } from './features/login-by-password/login-by-password.component';
// Third-party modules
import { NgxSpinnerModule } from 'ngx-spinner';
import { CreateWalletSaveComponent } from './features/create-wallet-save/create-wallet-save.component';
import { ClipboardModule } from 'ngx-clipboard';
import { Erc20BalanceComponent } from './features/erc20-balance/erc20-balance.component';
import { Erc20TransferComponent } from './features/erc20-transfer/erc20-transfer.component';
import { Erc20TransferConfirmComponent } from './features/erc20-transfer-confirm/erc20-transfer-confirm.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { AltHeaderComponent } from './shared/components/alt-header/alt-header.component';
import { ContentNftMintComponent } from './features/content-nft-mint/content-nft-mint.component';
import { NftMenuComponent } from './features/nft-menu/nft-menu.component';
import { CreateBrandComponent } from './features/create-brand/create-brand.component';
import { ContentTypeSelectComponent } from './features/content-type-select/content-type-select.component';
import { register } from 'swiper/element/bundle';

register();

@NgModule({
  declarations: [
    AppComponent,
    LaunchComponent,
    SelectWalletComponent,
    CreateWalletComponent,
    LoginByPasswordComponent,
    ContentNftListComponent,
    ContentNftDetailComponent,
    CreateBySecretKeyComponent,
    ContentNftSendResultComponent,
    CreateWalletSaveComponent,
    Erc20BalanceComponent,
    Erc20TransferComponent,
    Erc20TransferConfirmComponent,
    FooterComponent,
    HeaderComponent,
    AltHeaderComponent,
    ToBaseUnitPipe,
    ContentNftMintComponent,
    NftMenuComponent,
    CreateBrandComponent,
    ContentTypeSelectComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    ClipboardModule,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
