import { Component, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss'],
})
export class InformationDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<
    InformationDialogComponent,
      string | undefined
    >,
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
@Injectable({
  providedIn: 'root',
})
export class InformationDialogService {
  constructor(private dialog: MatDialog) {}
  /** dialog open */
  async open(): Promise<string | undefined> {
    const ref = this.dialog.open(InformationDialogComponent, {
      width: '90%',
      maxWidth: '390px',
      height: '200px',
    });
    const res = await firstValueFrom(ref.afterClosed());
    return res;
  }
}
