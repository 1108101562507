<div class="page-container">
  <div class="content-wrapper">
    <h1>Sanpō Wallet β</h1>
    <section class="btn-container">
      <button class="primary-btn" (click)="start()">Start</button>
    </section>
  </div>
</div>
<div class="footer-logo">
  <img src="assets/image/logo_solid.png" class="logo" />
</div>
