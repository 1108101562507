<ngx-spinner type="square-jelly-box" size="medium" [fullScreen]="true">
  <p style="color: white">Processing...</p>
</ngx-spinner>
<!-- header -->
<app-header></app-header>

<!-- main content -->
<div class="page-container">
  <!-- back button -->
  <section class="back-button-container">
    <div class="back-btn" routerLink="/spt-transfer">
      <mat-icon>navigate_before</mat-icon>
      <div>Back</div>
    </div>
  </section>
  <section class="main-container">
    <div class="flex-row detail-row">
      <div class="label">From:</div>
      <div class="value">{{ email }} (You)</div>
    </div>
    <div class="flex-row detail-row" style="margin-bottom: 5px;">
      <div class="label">To:</div>
      <div class="value to-value">{{ addressBook?.name }}</div>
    </div>
    <div class="flex-row detail-row">
      <div class="label"></div>
      <div class="value to-value">{{ toAddress }}</div>
    </div>
    <div class="flex-row detail-row">
      <div class="label">Balance:</div>
      <div class="value">{{ this.balance | toBaseUnit | number }} → {{ this.afterBalance | toBaseUnit | number }}</div>
    </div>
    <div class="flex-row detail-row">
      <div class="label">Send:</div>
      <div class="value">{{ this.receiveAmount | toBaseUnit | number }}</div>
    </div>
    <div class="flex-row detail-row">
      <div class="label">Burn:</div>
      <div class="value">{{ this.burnAmount | toBaseUnit | number }}</div>
    </div>
    <div class="flex-row detail-row" style="margin-bottom: 80px;">
      <div class="label">Amount:</div>
      <div class="value">{{ this.amount | toBaseUnit | number }}</div>
    </div>
    <button
      type="button"
      class="primary-btn"
      (click)="transfer()"
      [disabled]="!formIsValid"
    >
      Transfer
    </button>
  </section>
</div>
