import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from 'src/app/services/content/content.service';
import { AppService } from 'src/app/services/app/app.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { KeyService } from 'src/app/services/key/key.service';
import { ClipboardService } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogService } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { CryptService } from 'src/app/services/crypt/crypt.service';
import { MintDialogService } from 'src/app/shared/components/mint-dialog/mint-dialog.component';
import { PasswordDialogService } from 'src/app/shared/components/password-dialog/password-dialog.component';
import { AddBrandDialogService } from 'src/app/shared/components/add-brand-dialog/add-brand-dialog.component';
import { PublicStorageService } from 'src/app/services/public-storage/public-storage.service';
import { Web3Service } from 'src/app/services/web3/web3.service';
import { Contract } from 'src/app/models/models.types';

@Component({
  selector: 'app-content-nft-list',
  templateUrl: './content-nft-list.component.html',
  styleUrls: ['./content-nft-list.component.scss'],
})
export class ContentNftListComponent implements OnInit {
  serviceName = '';
  walletAddress = '';
  email = '';
  isError = false;
  canMint = true;
  nfts: any[] = [];
  ready = false;
  baseContract: any;
  brands: any[] = [];
  genres = [{ name: 'Card', key: 'card' }, { name: 'Book', key: 'book' }, { name: 'Music', key: 'music' }, { name: 'Movie', key: 'movie' }];
  selectedGenres: string[] = [];
  draftContents: Record<string, any[]> = {};

  constructor(
    private router: Router,
    private storageService: StorageService,
    private appService: AppService,
    private contentService: ContentService,
    private confirmDialog: ConfirmDialogService,
    private cryptService: CryptService,
    private mintDialog: MintDialogService,
    private passwordDialog: PasswordDialogService,
    private addBrandDialog: AddBrandDialogService,
    private publicStorageService: PublicStorageService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private keyService: KeyService,
    private web3Service: Web3Service,
    private _clipboardService: ClipboardService,
    private _snackBar: MatSnackBar,
  ) { }

  async ngOnInit(): Promise<void> {
    this.walletAddress = (await this.storageService.getWalletAddress()) ?? '';
    this.serviceName = await this.appService.getContractServiceName();
    this.email = await this.keyService.getDecryptEmailAddress();

    this.spinner.show();
    const contractServiceList = await this.storageService.getContractList();
    this.baseContract = this.appService.getContractJsonObject('manual');
    let invalid = [];

    this.brands = [];

    for (const contract of contractServiceList) {
      this.web3Service.changeContract(
        this.baseContract.content.abi,
        contract.address,
      );

      try {
        const objectIds = await this.web3Service.ownedObjectsOf(this.walletAddress);
        const objectIdList = objectIds._ownedObjects;
        const specIdArray = objectIdList.length > 0
          ? await this.web3Service.batchRequest(objectIdList)
          : [];
        let specIds: any[] = [];
        if (specIdArray) {
          // tempの要素から重複をなくす
          specIds = specIdArray.filter((item, index) => specIdArray.indexOf(item) === index);
        }
        const promises = specIds.map((specId) => {
          return this.web3Service.getDigitalContentSpec(specId);
        });
        const products = (await Promise.all(promises)).reverse();
        const owner = await this.web3Service.getContractOwner();

        this.brands.unshift({
          name: contract.name,
          address: contract.address,
          creator: owner.owner,
          products,
        });
      } catch (error) {
        console.log(error);
        invalid.push(contract.address);
        continue;
      }
    }
    invalid.forEach((address) => {
      contractServiceList.splice(contractServiceList.findIndex((contract) => contract.address === address), 1);
      this.storageService.setContractList(contractServiceList);
    });
    console.log(this.brands);

    // NFT画像読込
    this.brands.forEach((brand) => {
      brand.products.forEach((product: any) => {
        const mediaId = product.spec.thumbnailId.split('/').pop();
        this.publicStorageService.fileDownload("dummy_token", mediaId, this.walletAddress, brand.address, product.spec.specId)
          .then((imageFile: any) => {
            if (imageFile.indexOf('data:image') === -1) {
              const errorResponse = JSON.parse(imageFile);
              if (errorResponse.hasOwnProperty('error')) {
                const textLoading = document.getElementById(`${product.spec.specId}_loading`);
                textLoading!.style.display = 'none';
                const error: any = document.getElementById(`${product.spec.specId}_error`);
                error!.innerText = errorResponse.error.includes('Insufficient')
                  ? 'Insufficient account balance.' : errorResponse.error;
                const alert: any = document.getElementById(`${product.spec.specId}_alert`);
                alert!.style.display = '';
                return;
              }
            }
            product.image = imageFile;
          })
          .catch((error) => {
            console.log("error!", mediaId);
            console.log(error);
            const textLoading = document.getElementById(`${product.spec.specId}_loading`);
            textLoading!.style.display = 'none';
            const alert: any = document.getElementById(`${product.spec.specId}_alert`);
            alert!.style.display = '';
            return;
          });
      });
    });

    const draftContents = await this.storageService.getDraftContents();
    const tempDraftContents = await Promise.all(
      draftContents.map(async (content, idx) => {
        const info = JSON.parse(content.designData[8][0]);
        let jobStatus = true;
        const cids: any[] = [];
        for (const file of info.files) {
          if (file.jobId) {
            const res = await this.publicStorageService.checkForDRMStatus(
              "dummy_token",
              this.walletAddress,
              file.jobId,
              content.contractAddress,
            );
            if (!res.status) {
              jobStatus = false;
              break;
            }
            cids.push(res.cids);
          } else {
            cids.push(file.cid);
          }
        }
        return [content.contractAddress, { cids, jobStatus, idx, designData: content.designData }];
      }),
    );
    this.draftContents = tempDraftContents.reduce((acc, tmp, i) => {
      const [address, content] = tmp as [string, any];
      acc[address] = acc[address] ?? [];
      acc[address].push({
        ...content,
        spec: {
          specId: `${address}_${i}`,
          name: content.designData[2],
        },
      });
      return acc;
    }, {} as Record<string, any>);

    this.ready = true;
    this.spinner.hide();
  }

  async navigateMint(name: string, address: string) {
    this.web3Service.changeContract(
      this.baseContract?.content.abi,
      address,
    );
    await this.storageService.setContractAddress(address);
    await this.storageService.setContractName(name);
    this.router.navigate([`/content-nft-select-type`]);
  }

  navigateCreateBrand() {
    this.router.navigate(['/create-brand']);
  }

  navigateNftMenu() {
    this.router.navigate(['/nft-menu']);
  }

  isMyContract(nfts: any) {
    return this.walletAddress.toLowerCase() === nfts.creator.toLowerCase();
  }

  async openAddBrand() {
    const data = await this.addBrandDialog.open();
    if (!data) {
      return;
    }
    const brand = JSON.parse(data);

    if (!this.web3Service.isAddress(brand.address)) {
      await this.confirmDialog.openComplete(
        'Invalid contract address.'
      );
      return;
    }

    let contractServiceList: Contract[] = await this.storageService.getContractList();
    const alreadyUse = contractServiceList.find(
      (contract) => contract.address === brand.address || contract.name === brand.name
    );
    if (alreadyUse) {
      await this.confirmDialog.openComplete(
        'This contract address or name is already registered'
      );
      return;
    }
    contractServiceList.push({
      address: brand.address,
      name: brand.name,
    });
    await this.storageService.setContractList(contractServiceList);
    this.brands = [];
    this.ngOnInit();
  }

  async navigateDetail(address: string, product: any) {
    if (product.jobStatus !== undefined) {
      if (product.jobStatus) {
        // Ready to mint
        const confirmation = await this.confirmDialog.openConfirm(
          "Are you sure you want to mint this NFT?",
        );
        if (!confirmation) {
          return;
        }

        const hasMinted = await this.mintPendingContent(address, product);
        if (hasMinted) {
          await this.storageService.removeDraftContent(product.idx);
          this.ngOnInit();
        }
      }
      return;
    }

    const alert: any = document.getElementById(`${product.spec.specId}_alert`);
    if (alert!.style.display === '') {
      return; // do nothing...
    }

    await this.storageService.setContractAddress(address);
    this.web3Service.changeContract(
      this.baseContract.content.abi,
      address,
    );
    this.router.navigate([`/content-nft-detail/${product.spec.specId}`]);
  }

  async logout() {
    await this.authService.logOut();
  }

  backTo() {
    this.storageService.clearContractAddress();
    this.router.navigate(['/nft-menu']);
  }

  // ウォレットアドレスをコピー
  copyWalletAddress() {
    const decryptKey = this.walletAddress;
    this._clipboardService.copy(decryptKey);
    // snackBar表示
    this.openSnackBar('Copied your wallet address.');
  }

  copyContractAddress(address: string) {
    this._clipboardService.copy(address);
    this.openSnackBar('Copied smart contract address.');
  }

  // スナックバー
  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 4000, // 4s
      panelClass: ['brown-snackbar'],
      verticalPosition: 'bottom',
    });
  }

  clickName(address: string) {
    const dummy = document.getElementById(address + '_dummy');
    const list = document.getElementById(address + '_list');
    const showMore = document.getElementById(address + '_showmore');
    if (list!.style.display === 'none') {
      dummy!.style.display = 'none';
      list!.style.display = 'block';
      showMore!.style.display = 'none';
    } else {
      dummy!.style.display = 'block';
      list!.style.display = 'none';
      showMore!.style.display = 'block';
    }
  }

  toggleGenre(genreKey: string) {
    if (this.selectedGenres.includes(genreKey)) {
      this.selectedGenres.splice(this.selectedGenres.indexOf(genreKey), 1);
    } else {
      this.selectedGenres.push(genreKey);
    }
  }

  async mintPendingContent(contractAddress: string, product: any) {
    const mintAll = true;
    const totalSupplyLimit = product.designData[7];
    product.designData = JSON.parse(JSON.stringify(product.designData));
    let info = JSON.parse(product.designData[8][0]);
    info = {
      ...info,
      files: (info.files as any[]).map((file: any, i) => {
        if (file.jobId) {
          const { jobId: _, ...fileData } = file;
          return {
            ...fileData,
            cid: product.cids[i],
          };
        }
        return file;
      }),
    };
    product.designData[8][0] = JSON.stringify(info);

    const password = await this.passwordDialog.open();
    if (!password) {
      return;
    }
    const address = this.walletAddress;
    const privateKey = (await this.storageService.getPrivateKey()) ?? '';
    //復号鍵はここでのみ使用する
    const decryptedPrivateKey = this.cryptService.decryption(
      privateKey,
      password
    );
    const checkPrivateKeyToAddress =
      await this.keyService.checkPrivateKeyToAddress(
        this.walletAddress,
        decryptedPrivateKey
      );
    if (!checkPrivateKeyToAddress) {
      this.confirmDialog.openComplete('Password is invalid');
      return;
    }

    this.spinner.show();

    // チャレンジデータ取得
    const challenge = await this.publicStorageService.challenge(this.walletAddress);
    if ('error' in challenge) {
      this.openSnackBar(challenge.error);
      this.spinner.hide();
      return;
    }

    const signedChallenge = await this.web3Service.sign(decryptedPrivateKey, challenge.message);
    const auth = await this.publicStorageService.auth(
      this.walletAddress,
      challenge.id,
      signedChallenge,
    );
    console.log("DEBUG auth", auth);
    if (auth.hasOwnProperty('error')) {
      this.openSnackBar(auth.error);
      this.spinner.hide();
      return;
    }

    const token = auth.token;

    // Designのトランザクションデータ生成
    const serializedTx = await (this.web3Service.createDesignTx as any)(...product.designData);
    const res = await this.publicStorageService.design(
      token,
      this.walletAddress,
      serializedTx,
      contractAddress,
    );

    // Mintのトランザクションデータ作成
    const mintInfo = {
      issuer: this.walletAddress
    };

    const mintTxList = [];
    let nonce: any = await this.web3Service.getTransactionCount(this.walletAddress);
    const num = mintAll ? totalSupplyLimit : 1;
    for (let i = 0; i < num; i++) {
      mintTxList.push(this.web3Service.createMintTx(
        this.walletAddress, // address
        decryptedPrivateKey, // privateKey
        this.walletAddress, // _to
        res.DesignLog._specId, // _specId
        product.cids[0], // _mediaId
        [JSON.stringify(mintInfo)], // _info
        'beta1',
        nonce++, // nonce
      ));
    }
    const mintRes = await this.publicStorageService.mint(
      token,
      this.walletAddress,
      mintTxList,
      contractAddress,
    )
    this.spinner.hide();
    await this.confirmDialog.openComplete('Done!');

    return true;
  }

  getProductsForBrand(brand: any) {
    return ([] as any[]).concat(this.draftContents[brand.address] ?? []).concat(brand.products ?? []);
  }

  get filteredBrands() {
    if (this.selectedGenres.length === 0) return this.brands;

    return this.brands
      .map((brand) => {
        const tmp = { ...brand };
        tmp.products = brand.products.filter((product: any) => {
          return this.selectedGenres.some((genre) => {
            return product.spec.contentType === genre;
          });
        });
        return tmp;
      })
      .filter((brand) => brand.products.length > 0);
  }
}
