<!-- header -->
<app-alt-header></app-alt-header>

<div class="page-container">
  <!-- back button -->
  <section class="back-button-container">
    <div class="back-btn" routerLink="/select-wallet">
      <mat-icon>navigate_before</mat-icon>
      <div>Back</div>
    </div>
  </section>
  <!-- main content -->
  <section class="main-container">
    <form [formGroup]="form">
      <div class="input-wrapper">
        <label>Secret key</label>
        <mat-form-field appearance="fill">
          <mat-label>Your secret key</mat-label>
          <input
            type="text"
            matInput
            formControlName="secretKey"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="input-wrapper">
        <label>My Wallet Name</label>
        <mat-form-field appearance="fill">
          <mat-label>Max 25 alphanumeric characters</mat-label>
          <input
            type="text"
            matInput
            formControlName="email"
            autocomplete="off"
            maxlength="25"
          />
        </mat-form-field>
      </div>
      <div class="input-wrapper">
        <label>Wallet password</label>
        <mat-form-field appearance="fill">
          <mat-label>At least 8 alphanumeric characters</mat-label>
          <input
            id="password"
            type="password"
            matInput
            formControlName="password"
            autocomplete="new-password"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>confirm At least 8 alphanumeric characters</mat-label>
          <input
            type="password"
            matInput
            formControlName="confirm"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </form>
  </section>
  <section class="btn-container">
    <button
      class="primary-btn"
      (click)="login()"
      [disabled]="this.form.invalid"
    >
      Next
    </button>
  </section>
</div>
