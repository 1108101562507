import { Injectable } from '@angular/core';
import { Web3Service } from '../web3/web3.service';
import { BehaviorSubject } from 'rxjs';
import { Erc20BalanceOf } from 'src/app/models/models.types';

@Injectable({
  providedIn: 'root',
})
export class DepositManagementService {
  private _balance$: BehaviorSubject<Erc20BalanceOf> =
    new BehaviorSubject<Erc20BalanceOf>({ balance: '0' });
  private _amount: string;
  constructor(private web3Service: Web3Service) {
    this._amount = '';
  }
  get amount(): string {
    return this._amount;
  }

  set amount(amount: string) {
    this._amount = amount;
  }
  /** トークンバランス */
  get balance$() {
    return this._balance$.asObservable();
  }
  get balance() {
    return this._balance$.getValue();
  }
  nextBalance(balance: Erc20BalanceOf) {
    this._balance$.next(balance);
  }
  clearBalance() {
    this._balance$.next({ balance: '0' });
  }
  // BN変換
  toBN(_value: number | string) {
    return this.web3Service.toBN(_value);
  }

  // アドレスチェック
  isAddress(_address: string) {
    return this.web3Service.isAddress(_address);
  }

  /**以下web3からの取得 *ABIファイルに依存しているためmanualだと動かない可能性あり */

  /**
   * チェーンからDepositManagement残高情報を取得し更新する
   * @param _address 検索するアドレス
   */
  async fetchBalance(_address: string) {
    try {
      const result = await this.getBalance(_address);
      if (!!result) {
        this.nextBalance(result);
      } else {
        this.clearBalance();
      }
    } catch (e) {
      this.clearBalance();
      throw e;
    }
  }
  /**
   * チェーンからDepositManagement残高情報を取得する
   * @param _address
   * @returns
   */
  async getBalance(_address: string): Promise<Erc20BalanceOf | null> {
    try {
      const balance: Erc20BalanceOf = await this.web3Service.balanceOfDeposit(
        _address
      );
      return balance;
    } catch (e) {
      throw e;
    }
  }
}
