<!-- header -->
<app-header></app-header>

<!-- main content -->
<div class="page-container">
  <div class="card-wrapper">
    <div class="card-container" style="margin-top: 50px; cursor: pointer; height: 80px;" (click)="navigateMyNfts()">
      <div class="flex-row" style="width: calc(100% - 40px); height: 100%; justify-content: flex-start;">
        <mat-icon class="nfts-icon" svgIcon="images-regular"></mat-icon>
        <h3>My Gallery</h3>
      </div>
    </div>
    <div class="flex-row" style="margin-top: 100px;">
      <div class="flex-row" style="width: calc(100% - 40px); cursor: pointer;  justify-content: flex-start;"
        (click)="navigateCreateBrand()">
        <mat-icon class="create-icon" svgIcon="pen-to-square-regular"></mat-icon>
        <h4 style="text-decoration: underline; margin: unset;">Create new Brand</h4>
      </div>
    </div>
    <p style="margin: unset; padding-left: 60px;">If you would like to create your original NFTs, choose here.</p>
  </div>
</div>