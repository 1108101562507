import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-content-type-select',
  templateUrl: './content-type-select.component.html',
  styleUrls: ['./content-type-select.component.scss'],
})
export class ContentTypeSelectComponent implements OnInit {
  constructor(
    private location: Location,
    private router: Router,
    private storageService: StorageService,
  ) {}

  ngOnInit(): void {}

  back() {
    this.router.navigate([`/content-nft-list`]);
  }

  async selectContentType(contentType: string) {
    await this.storageService.setContentType(contentType);
    this.router.navigate([`/content-nft-mint`]);
  }
}
