import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

import { AppService } from 'src/app/services/app/app.service';
import { ConfirmDialogService } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { CryptService } from 'src/app/services/crypt/crypt.service';
import { PasswordDialogService } from 'src/app/shared/components/password-dialog/password-dialog.component';
import { PublicStorageService } from 'src/app/services/public-storage/public-storage.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { Web3Service } from 'src/app/services/web3/web3.service';
import { Contract } from 'src/app/models/models.types';

import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-brand',
  templateUrl: './create-brand.component.html',
  styleUrls: ['./create-brand.component.scss']
})
export class CreateBrandComponent implements OnInit {
  contractServiceList: Contract[] = [];
  brandName = new FormControl(null);
  address = '';

  constructor(
    private router: Router,
    private location: Location,
    private snackBar: MatSnackBar,
    private appService: AppService,
    private confirmDialog: ConfirmDialogService,
    private cryptService: CryptService,
    private passwordDialog: PasswordDialogService,
    private publicStorageService: PublicStorageService,
    private storageService: StorageService,
    private web3Service: Web3Service,
    private spinner: NgxSpinnerService,
  ) { }

  async ngOnInit() {
    this.address = (await this.storageService.getWalletAddress()) ?? '';
    this.contractServiceList = await this.storageService.getContractList();
  }

  back() {
    this.location.back();
  }

  get formForDeployIsValid() {
    const res = this.brandName.value ? true : false;
    return res;
  }

  async deployContract() {
    const name = this.brandName.value;
    const contract = { name };
    const alreadyUse = this.contractServiceList.find(
      (contract) => contract.name === name
    );
    if (alreadyUse) {
      await this.confirmDialog.openComplete(`${name} is already registered`);
      return;
    }

    const password = await this.passwordDialog.open();
    if (!password) {
      return;
    }
    const address = this.address;
    const privateKey = (await this.storageService.getPrivateKey()) ?? '';
    const decryptedPrivateKey = this.cryptService.decryption(
      privateKey,
      password
    );
    if (!decryptedPrivateKey) {
      this.confirmDialog.openComplete('Password is invalid');
      return;
    }

    this.spinner.show();
    const challenge = await this.publicStorageService.challenge(this.address);
    if (challenge.hasOwnProperty('error')) {
      this.openSnackBar(challenge.error);
      this.spinner.hide();
      return;
    }
    const signed = await this.web3Service.sign(decryptedPrivateKey, challenge.message);
    const auth = await this.publicStorageService.auth(
      this.address,
      challenge.id,
      signed,
    );
    if (auth.hasOwnProperty('error')) {
      this.openSnackBar(auth.error);
      this.spinner.hide();
      return;
    }

    const token = auth.token;

    // コントラクトをデプロイ
    const bytecode = await this.publicStorageService.getBytecode(token, this.address);
    const result = await this.web3Service.deployContract(this.address, decryptedPrivateKey, bytecode.bytecode);
    console.log(result);
    this.spinner.hide();

    // コントラクト情報を保存
    this.appService.setContractServiceName('manual');
    await this.storageService.setContractService('manual');
    await this.storageService.setContractAddress(result.contractAddress);
    await this.storageService.setContractName(this.brandName.value);
    this.contractServiceList.push({
      address: result.contractAddress,
      name: this.brandName.value,
    });
    await this.storageService.setContractList(this.contractServiceList);

    // コントラクトを選択
    const _contract = this.appService.getContractJsonObject('manual');
    this.web3Service.changeContract(
      _contract?.content.abi,
      result.contractAddress,
    );

    // Designページに遷移
    await this.confirmDialog.openComplete(
      `${name} is created.\nCreate the first content for your brand.`
    );
    this.router.navigate([`/content-nft-select-type`]);
  }

  // スナックバー
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 4000, // 4s
      panelClass: ['brown-snackbar'],
      verticalPosition: 'bottom',
    });
  }
}
