import { Injectable } from '@angular/core';
import {
  DigitalContentObject,
  DigitalContentSpec,
  ETHAccount,
  ObjectBalanceOf,
  OwnedObjectsOf,
  Erc20BalanceOf,
  BurnRate,
  Erc20Symbol,
} from 'src/app/models/models.types';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));
const Tx = require('ethereumjs-tx').Transaction;
const Common = require('ethereumjs-common').default;
const customCommon = Common.forCustomChain(
  'mainnet',
  {
    name: 'privatechain',
    networkId: 1,
    chainId: 11421,
  },
  'petersburg'
);
const ManualDigitalContentObjectJson = require('../../config/abi/manual/DigitalContentObject.json');
//@dev https://github.com/ChainSafe/web3.js/issues/1354
const providerOption = {
  reconnect: {
    auto: true,
    delay: 1000, // ms
    onTimeout: false,
    // maxAttempts:
  },
  timeout: 5000, // ms
  clientConfig: {
    maxReceivedFrameSize: 10000000000,
    maxReceivedMessageSize: 10000000000,
    keepalive: true,
    keepaliveInterval: 1000, // ms
    dropConnectionOnKeepaliveTimeout: true,
    keepaliveGracePeriod: 4000, // ms
  },
};
@Injectable({
  providedIn: 'root',
})
export class Web3Service {
  web3: any;
  provider: any;
  DigitalContentContract: any;
  Erc20Contract: any;
  DepositManagementContract: any;

  /** init Web3 */
  init() {
    this.provider = new Web3.providers.WebsocketProvider(
      environment.providerUrl,
      providerOption
    );
    this.web3 = new Web3(this.provider);
  }

  // BN変換
  toBN(_value: number | string) {
    return Web3.utils.toBN(_value);
  }

  // アドレスチェック
  isAddress(_address: string) {
    return Web3.utils.isAddress(_address);
  }
  /**
   * change contract
   * @param digitalContentContractAbi contract abi
   * @param digitalContentContractAddress contract address
   */
  changeContract(
    digitalContentContractAbi: any,
    digitalContentContractAddress: any
  ) {
    if (!this.web3) {
      this.init();
    }

    this.DigitalContentContract = null;
    this.DigitalContentContract = new this.web3.eth.Contract(
      digitalContentContractAbi,
      digitalContentContractAddress
    );
  }

  /**
   * change ERC20 contract
   * @param erc20ContractAbi contract abi
   * @param erc20ContractAddress contract address
   */
  setErc20Contract(erc20ContractAbi: any, erc20ContractAddress: any) {
    if (!this.web3) {
      this.init();
    }
    this.Erc20Contract = new this.web3.eth.Contract(
      erc20ContractAbi,
      erc20ContractAddress
    );
  }

  /**
   * change DepositManagement contract
   * @param depositManagementContractAbi contract abi
   * @param depositManagementAddress contract address
   */
  setDepositManager(
    depositManagementContractAbi: any,
    depositManagementAddress: any
  ) {
    if (!this.web3) {
      this.init();
    }
    this.DepositManagementContract = new this.web3.eth.Contract(
      depositManagementContractAbi,
      depositManagementAddress
    );
  }

  /**
   * マニュアル入力用
   * @param _digitalContentContractAbi
   * @param _digitalContentContractAddress
   */
  manualContract(
    _digitalContentContractAbi: any,
    _digitalContentContractAddress: any
  ) {
    this.DigitalContentContract = new this.web3.eth.Contract(
      _digitalContentContractAbi,
      _digitalContentContractAddress
    );
  }

  async isValidAddress(address: string) {
    if (this.web3.utils.isAddress(address)) {
      return true;
    }
    return false;
  }

  /**create Address */
  async createAddress(): Promise<ETHAccount> {
    if (!this.web3) {
      this.init();
    }
    const account = await this.web3.eth.accounts.create();
    return { address: account.address, privateKey: account.privateKey };
  }

  /**private_key to Address */
  async privateKeyToAddress(_privateKey: string) {
    if (!this.web3) {
      this.init();
    }
    try {
      const address = await this.web3.eth.accounts.privateKeyToAccount(
        _privateKey
      ).address;
      return address;
    } catch (e) {
      throw e;
    }
  }

  async deployContract(address: string, privateKey: any, bytecode: any) {
    const abi = ManualDigitalContentObjectJson.abi;
    const contract = new this.web3.eth.Contract(abi);
    const txData = await contract.deploy({ data: bytecode }).encodeABI();
    const nonce = await this.web3.eth.getTransactionCount(address);

    const rawTx = {
      from: address,
      to: contract.options.address,
      gas: 7800000,
      gasPrice: 0,
      data: txData,
      nonce: nonce,
    };
    let tx = new Tx(rawTx, { common: customCommon });
    tx.sign(Buffer.from(privateKey.split('0x')[1], 'hex'));
    let serializedTx = tx.serialize();
    let result;
    try {
      result = await this.web3.eth.sendSignedTransaction('0x' + serializedTx.toString('hex'));
    } catch (e) {
      console.log(e);
      result = { error: 'Failed to deploy contract' };
    }
    return result;
  }

  /**disconnect */
  disconnect() {
    if (!this.web3) return;
    this.web3.currentProvider.disconnect();
    this.web3 = null;
    this.DigitalContentContract = null;
  }

  getContractOwner(): Promise<any> {
    if (!this.web3) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      this.DigitalContentContract.methods
        .getContractOwner()
        .call()
        .then((result: any) => {
          resolve({ owner: result });
        })
        .catch((e: any) => {
          reject({ error: e });
        });
    });
  }

  // @see https://ethereum.stackexchange.com/questions/65194/get-creator-from-contract-address
  async getContractCreator(contract_address: string) {
    const block = await this._search_contract_cretion_block(contract_address);
    const creator = await this._search_contract_creator(contract_address, block);
    return creator;
  }

  private async _search_contract_cretion_block(contract_address: string) {
    let highest_block = await this.web3.eth.getBlockNumber();
    let lowest_block = 62000000;

    let contract_code = await this.web3.eth.getCode(contract_address, highest_block);
    if (contract_code == "0x") {
      console.error("Contract " + contract_address + " does not exist!");
      return -1;
    }

    while (lowest_block <= highest_block) {
      // @ts-ignore
      let search_block = parseInt((lowest_block + highest_block) / 2);
      try {
        contract_code = await this.web3.eth.getCode(contract_address, search_block);
      } catch (e) {
        contract_code = "0x";
      }
      // console.log(highest_block, lowest_block, search_block);
      if (contract_code != "0x") {
        highest_block = search_block;
      } else if (contract_code == "0x") {
        lowest_block = search_block;
      }

      if (highest_block == lowest_block + 1) {
        return highest_block;
      }
    }
  }

  private async _search_contract_creator(contract_address: string, block: number) {
    const _block = await this.web3.eth.getBlock(block);
    let transactions = _block.transactions;
    for (const transaction in transactions) {
      let receipt = await this.web3.eth.getTransactionReceipt(transactions[transaction]);
      if (receipt.contractAddress == contract_address) {
        return receipt.from;
      }
    }
    return "0x";
  }


  /**以下　ABI依存の関数 */
  /**
   * object transfer
   * @param _address
   * @param _privateKey
   * @param _from
   * @param _to
   * @param _objectId
   * @returns
   */
  objectTransferFrom(
    _address: any,
    _privateKey: any,
    _from: any,
    _to: any,
    _objectId: any
  ) {
    if (!this.web3) {
      this.init();
    }
    const txData = this.DigitalContentContract.methods
      .transferFrom(_from, _to, _objectId)
      .encodeABI();
    return this._sendSignedTransaction(
      _address,
      _privateKey,
      txData,
      this.DigitalContentContract.options.address
    );
  }

  /**
   * get digital-content-object by specID
   * @param _specId
   * @returns
   */
  getDigitalContentSpec(_specId: number): Promise<DigitalContentSpec> {
    if (!this.web3) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      this.DigitalContentContract.methods
        .getDigitalContentSpec(_specId)
        .call()
        .then((result: any) => {
          resolve({ spec: result });
        })
        .catch((e: any) => {
          reject({ error: e });
        });
    });
  }

  /**
   * get digital-content-object by objectID
   * @param _objectId
   * @returns
   */
  getDigitalContentObject(_objectId: number): Promise<DigitalContentObject> {
    if (!this.web3) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      this.DigitalContentContract.methods
        .getDigitalContentObject(_objectId)
        .call()
        .then((result: any) => {
          resolve({ object: result });
        })
        .catch((e: any) => {
          reject({ error: e });
        });
    });
  }

  /**
   * has object number by address
   * @param _owner owner address
   * @returns
   */
  objectBalanceOf(_owner: string): Promise<ObjectBalanceOf> {
    if (!this.web3) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      this.DigitalContentContract.methods
        .objectBalanceOf(_owner)
        .call()
        .then((result: any) => {
          resolve({ _ownedObjectsCount: result });
        })
        .catch((e: any) => {
          reject({ error: e });
        });
    });
  }

  async batchRequest(objectIdList: any) {
    try {
      const batch = new this.web3.BatchRequest();
      let res: any[] = [];
      for (const objectId of objectIdList) {
        batch.add(
          this.DigitalContentContract.methods.specIdOf(objectId).call.request((error: any, result: any) => {
            if (error) {
              console.log(error);
            }
            res.push(result);
          })
        )
      }
      batch.execute();
      let isProcessing = true;
      while (isProcessing) {
        if (res.length == objectIdList.length) {
          isProcessing = false;
          break;
        }
        await sleep(100);
      }

      return res;
    } catch (e) {
      console.log(e);
      return;
    }
  }

  /**
   * specIdOf
   * @param _objectId
   * @returns
   */
  specIdOf(_objectId: number): Promise<any> {
    if (!this.web3) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      this.DigitalContentContract.methods
        .specIdOf(_objectId)
        .call()
        .then((result: any) => {
          resolve(result);
        })
        .catch((e: any) => {
          reject({ error: e });
        });
    });
  }

  /**
   * has objectIds by address
   * @param _owner owner address
   * @returns
   */
  ownedObjectsOf(_owner: string): Promise<OwnedObjectsOf> {
    if (!this.web3) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      this.DigitalContentContract.methods
        .ownedObjectsOf(_owner)
        .call()
        .then((result: any) => {
          resolve({ _ownedObjects: result });
        })
        .catch((e: any) => {
          reject({ error: e });
        });
    });
  }

  totalSupplyOf(_specId: number): Promise<any> {
    if (!this.web3) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      this.DigitalContentContract.methods
        .totalSupplyOf(_specId)
        .call()
        .then((result: any) => {
          resolve({ totalSupply: result });
        })
        .catch((e: any) => {
          reject({ error: e });
        });
    });
  }

  /**
   * balance of ERC20 token
   */
  balanceOf(_owner: string): Promise<Erc20BalanceOf> {
    if (!this.web3) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      this.Erc20Contract.methods
        .balanceOf(_owner)
        .call()
        .then((result: any) => {
          resolve({ balance: result });
        })
        .catch((e: any) => {
          reject({ error: e });
        });
    });
  }

  /**
   * get burn rate
   */
  getBurnRate(): Promise<BurnRate> {
    if (!this.web3) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      this.Erc20Contract.methods
        .burnRateOf()
        .call()
        .then((result: any) => {
          resolve({ burnRate: result });
        })
        .catch((e: any) => {
          reject({ error: e });
        });
    });
  }

  /**
   * get Symbol
   */
  getSymbol(): Promise<Erc20Symbol> {
    if (!this.web3) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      this.Erc20Contract.methods
        .symbol()
        .call()
        .then((result: any) => {
          resolve({ symbol: result });
        })
        .catch((e: any) => {
          reject({ error: e });
        });
    });
  }

  /**
   * balance of depositManagement token
   */
  balanceOfDeposit(_owner: string): Promise<Erc20BalanceOf> {
    if (!this.web3) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      this.DepositManagementContract.methods
        .balanceOf(_owner)
        .call()
        .then((result: any) => {
          resolve({ balance: result });
        })
        .catch((e: any) => {
          reject({ error: e });
        });
    });
  }

  /**
   * transBurn approve
   */
  approve(
    _address: string,
    _privateKey: string,
    _depositAddress: string,
    _amount: string
  ) {
    if (!this.web3) {
      this.init();
    }
    const txData = this.Erc20Contract.methods
      .approve(_depositAddress, _amount)
      .encodeABI();
    return this._sendSignedTransaction(
      _address,
      _privateKey,
      txData,
      environment.sptContractAddress,
    );
  }

  /**
   * object transfer
   * @param _address
   * @param _privateKey
   * @param _to
   * @param _value
   * @returns
   */
  erc20TokenTransfer(
    _address: any,
    _privateKey: any,
    _to: any,
    _value: string
  ) {
    if (!this.web3) {
      this.init();
    }
    const txData = this.Erc20Contract.methods.transfer(_to, _value).encodeABI();
    return this._sendSignedTransaction(
      _address,
      _privateKey,
      txData,
      environment.sptContractAddress,
    );
  }

  /**
   * Content Design
   * @param _address
   * @param _privateKey
   * @param _name
   * @param _symbol
   * @param _contentType
   * @param _mediaId
   * @param _totalSupplyLimit
   * @param _info
   * @param _originalSpecIds
   * @param _contractDocuments
   * @param _copyrightFeeRatio
   * @param _allowSecondaryMerket
   * @returns
   */
  design(
    _address: any,
    _privateKey: any,
    _name: any,
    _symbol: any,
    _contentType: any,
    _mediaId: any,
    _totalSupplyLimit: any,
    _info: any,
    _originalSpecIds: any,
    _contractDocuments: any,
    _copyrightFeeRatio: any,
    _allowSecondaryMerket: any
  ) {
    const txData = this.DigitalContentContract.methods
      .design(
        _name,
        _symbol,
        _contentType,
        _mediaId,
        _totalSupplyLimit,
        _info,
        _originalSpecIds,
        _contractDocuments,
        _copyrightFeeRatio,
        _allowSecondaryMerket
      )
      .encodeABI();
    return this._sendSignedTransaction(
      _address,
      _privateKey,
      txData,
      this.DigitalContentContract.options.address
    );
  }

  /**
   * Content Design
   * @param _address
   * @param _privateKey
   * @param _name
   * @param _symbol
   * @param _contentType
   * @param _mediaId
   * @param _totalSupplyLimit
   * @param _info
   * @param _originalSpecIds
   * @param _contractDocuments
   * @param _copyrightFeeRatio
   * @param _allowSecondaryMerket
   * @returns
   */
  async createDesignTx(
    _address: any,
    _privateKey: any,
    _name: any,
    _symbol: any,
    _contentType: any,
    _mediaId: any,
    _thumbnailId: any,
    _totalSupplyLimit: any,
    _information: any,
    _agreements: any,
    _drm: any,
    _personaInformation: any,
    _secondarySales: any,
    _royalty: any,
    _deleted: any,
    _contractVersion: any,
  ) {
    const txData = this.DigitalContentContract.methods
      .design(
        _name,
        _symbol,
        _contentType,
        _mediaId,
        _thumbnailId,
        _totalSupplyLimit,
        _information,
        _agreements,
        _drm,
        _personaInformation,
        _secondarySales,
        _royalty,
        _deleted,
        _contractVersion,
      )
      .encodeABI();
    const nonce = await this.web3.eth.getTransactionCount(_address, 'pending');
    const rawTx = {
      from: _address,
      to: this.DigitalContentContract.options.address,
      gas: 4700000,
      gasPrice: 0,
      data: txData,
      nonce: nonce,
    };
    const tx = new Tx(rawTx, { common: customCommon });
    tx.sign(Buffer.from(_privateKey.split('0x')[1], 'hex'));
    const serializedTx = tx.serialize();
    return "0x" + serializedTx.toString("hex");
  }

  createMintTx(
    _address: any,
    _privateKey: any,
    _to: any,
    _specId: any,
    _mediaId: any,
    _information: any,
    _contractVersion: any,
    _nonce: any,
  ) {
    const txData = this.DigitalContentContract.methods
      .mint(
        _to,
        _specId,
        _mediaId,
        _information,
        _contractVersion,
      )
      .encodeABI();
    const rawTx = {
      from: _address,
      to: this.DigitalContentContract.options.address,
      gas: 4700000,
      gasPrice: 0,
      data: txData,
      nonce: _nonce,
    };
    const tx = new Tx(rawTx, { common: customCommon });
    tx.sign(Buffer.from(_privateKey.split('0x')[1], 'hex'));
    const serializedTx = tx.serialize();
    return "0x" + serializedTx.toString("hex");
  }

  async getTransactionCount(_address: any) {
    return await this.web3.eth.getTransactionCount(_address, 'pending');
  }

  async sign(privateKey: string, data: any) {
    const signature = await this.web3.eth.accounts.sign(data, privateKey);
    return signature.signature;
  }

  //private function
  /**
   * transactions
   * @param _from
   * @param _privateKey
   * @param _txData
   * @param _cAddress
   * @returns
   */
  async _sendSignedTransaction(
    _from: any,
    _privateKey: any,
    _txData: any,
    _cAddress: any
  ) {
    return new Promise(async (resolve, reject) => {
      //time out error
      setTimeout(() => reject({ error: { message: 'timeOut' } }), 30000);
      const nonce = await this.web3.eth.getTransactionCount(_from, 'pending');
      const rawTx = {
        from: _from,
        to: _cAddress,
        gas: 4700000,
        gasPrice: 0,
        data: _txData,
        nonce: nonce,
      };
      const tx = new Tx(rawTx, { common: customCommon });
      tx.sign(Buffer.from(_privateKey.split('0x')[1], 'hex'));
      const serializedTx = tx.serialize();
      this.web3.eth
        .sendSignedTransaction('0x' + serializedTx.toString('hex'))
        .on('confirmation', (confirmationNumber: any, receipt: any) => {
          if (confirmationNumber === 1) {
            resolve(receipt.transactionHash);
          }
        })
        .on('error', (e: any) => {
          console.log(e);
          reject({ error: e });
        });
    });
  }
}
