import { Pipe, PipeTransform } from '@angular/core';
import { Erc20Service } from 'src/app/services/content/erc20.service';
@Pipe({
  name: 'toBaseUnit'
})
export class ToBaseUnitPipe implements PipeTransform {

  constructor(
    private erc20Service: Erc20Service
  ) { }

  // @dev 表示用に小数点以下3桁までを返す

  /**
   * 表示用に小数点以下桁数を調整する
   * @param value 
   * @param digits 
   * @returns 
   */
  transform(value: string | undefined, digits: number = 18): string {
    const amount = this.erc20Service.toBaseUnit(value, digits);

    // 整数値の場合はそのまま返す
    if (amount.indexOf('.') === -1) return amount;

    const adjusted = this.adjust(amount, 3);
    return adjusted.replace(/0+$/g, '');
  }

  /**
   * 指定桁数で切り捨てし、有効であればその値を、ゼロなら更に桁数を増やして再帰的に調整する
   * @param amount 
   * @param digits 
   * @returns 
   */
  private adjust(amount: string, digits: number): string {
    const result = amount.substring(0, amount.indexOf('.') + 1 + digits);
    if (result.match(/[^0.]/)) {
      return result;
    } else {
      return this.adjust(amount, digits + 3);
    }
  }
}
