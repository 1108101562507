import { Component, Injectable } from '@angular/core';

@Component({
  selector: 'app-alt-header',
  templateUrl: './alt-header.component.html',
  styleUrls: ['./alt-header.component.scss']
})
export class AltHeaderComponent {
}

@Injectable({providedIn: 'root'})
export class AltHeaderService {
  constructor() { }
}
