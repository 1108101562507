<ngx-spinner type="square-jelly-box" size="medium" [fullScreen]="true">
  <p style="color: white">Processing...</p>
</ngx-spinner>
<!-- header -->
<app-header></app-header>

<!-- main content -->
<div class="page-container">
  <!-- back button -->
  <section class="back-button-container">
    <div class="back-btn" routerLink="/spt-balance">
      <mat-icon>navigate_before</mat-icon>
      <div>Back</div>
    </div>
  </section>

  <section class="main-container">
    <div class="flex-row detail-row">
      <div class="label">Asset:</div>
      <div class="value">{{ erc20Symbol?.symbol ? erc20Symbol?.symbol : "SPT" }}</div>
    </div>
    <div class="flex-row detail-row">
      <div class="label">Balance:</div>
      <div class="value to-value">{{ (this.erc20Balance$ | async)?.balance | toBaseUnit | number }}</div>
    </div>
    <div class="input-wrapper">
      <form [formGroup]="transferForm" style="margin-top: 10px;">
        <div>
          <mat-form-field appearance="fill">
            <mat-label>To:</mat-label>
            <input matInput formControlName="toAddress" class="address-input" />
            <mat-error *ngIf="transferForm.value.toAddress"
              >Address not valid</mat-error
            >
          </mat-form-field>
          <button
            mat-flat-button
            (click)="openAddressBook()"
            class="drop-down-button"
          >
            <mat-icon class="drop-down-icon">arrow_drop_down_circle</mat-icon>
          </button>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Send:</mat-label>
          <input matInput formControlName="amount" />
          <mat-error *ngIf="transferForm.value.amount"
            >Amount must be numric</mat-error
          >
        </mat-form-field>
        <section class="btn-container">
          <button
            type="button"
            class="primary-btn"
            (click)="next()"
            [disabled]="!transferForm.valid"
          >
            Next
          </button>
        </section>
      </form>
    </div>
    <div class="history-container">
      <h3>Send History:</h3>
      <div *ngFor="let hist of transferHistoryList" style="margin-top: 5px;">
        <div class="flex-row">
          <p style="width: 120px;">{{ hist.dateTime | date: "yy/MM/dd HH:mm" }}</p>
          <div class="flex-row" style="width: calc(100% - 120px); justify-content: space-around;">
            <p style="overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap; width: 50%;">To: {{ getNameFromAddress(hist.to) }}</p>
            <p style="width: 50%;">Send: {{ hist.value | toBaseUnit | number }}</p>
            </div>
        </div>
        <div class="flex-row">
          <p style="width: 120px;">&nbsp;</p>
          <div class="flex-row" style="width: calc(100% - 120px); justify-content: space-around;">
            <p style="width: 50%;"></p>
            <p style="width: 50%;">Burn: {{ hist.burn | toBaseUnit | number }}</p>
          </div>
        </div>
        <div class="flex-row">
          <p style="width: 120px;">&nbsp;</p>
          <div class="flex-row" style="width: calc(100% - 120px); justify-content: space-around;">
            <p style="width: 50%;">&nbsp;</p>
            <a
              href="{{ this.explorerBaseUrl }}/{{ hist.transactionHash }}"
              target="_blank"
              rel="noopener noreferrer"
              style="font-size: 13px; width: 50%;"
            >
              Detail
            </a>
          </div>
        </div>
        <hr style="margin: unset;" />
      </div>
    </div>
  </section>
</div>
