import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContentService } from 'src/app/services/content/content.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContentObject, Web3ContentSpec } from 'src/app/models/models.types';
import { PublicStorageService } from 'src/app/services/public-storage/public-storage.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AppService } from 'src/app/services/app/app.service';
import { KeyService } from 'src/app/services/key/key.service';
import { ClipboardService } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogService } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { SelectAddressDialogService } from 'src/app/shared/components/select-address-dialog/select-address-dialog.component';
import { PasswordDialogService } from 'src/app/shared/components/password-dialog/password-dialog.component';
import { TransferService } from 'src/app/services/transfer/transfer.service';
import { CryptService } from 'src/app/services/crypt/crypt.service';
import { Web3Service } from 'src/app/services/web3/web3.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContentNftPlayerComponent } from 'src/app/shared/components/content-nft-player/content-nft-player.component';

@Component({
  selector: 'app-content-nft-detail',
  templateUrl: './content-nft-detail.component.html',
  styleUrls: ['./content-nft-detail.component.scss'],
})
export class ContentNftDetailComponent implements OnInit {
  // 表示項目
  serviceName = '';
  walletAddress = '';
  email = '';
  showMore = false;
  contentObject: ContentObject | null = null;
  imageSource: string | null = null;
  contentSpec: Web3ContentSpec | null = null;
  specInfo!: any;
  description: any;
  spec: any;
  objectId: any[] = [];
  objectIndex: any[] = [];

  // 転送用項目
  addressForm: FormGroup = this.fb.group({
    address: [null, [Validators.required]],
    selectedObjectId: [null, [Validators.required]],
  });
  select: any[] = [];
  mode = 1; // 1: view, 2: transfer

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private storageService: StorageService,
    private confirmDialog: ConfirmDialogService,
    private appService: AppService,
    private contentService: ContentService,
    private publicStorageService: PublicStorageService,
    private authService: AuthService,
    private cryptService: CryptService,
    private web3Service: Web3Service,
    private keyService: KeyService,
    private transferService: TransferService,
    private spinner: NgxSpinnerService,
    private _clipboardService: ClipboardService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private selectAddressDialog: SelectAddressDialogService,
    private passwordDialog: PasswordDialogService,
    private dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<void> {
    this.walletAddress = (await this.storageService.getWalletAddress()) ?? '';
    this.email = await this.keyService.getDecryptEmailAddress();
    this.serviceName = await this.appService.getContractServiceName();
    const specId = this.activeRoute.snapshot.params['specId'];
    this.spinner.show();
    try {
      this.spec = await this.contentService.getContentSpecBySpecId(specId);
      const content = {};
      const objectId = await this.web3Service.ownedObjectsOf(this.walletAddress);
      const totalSupply = await this.web3Service.totalSupplyOf(specId);
      this.spec.totalSupply = totalSupply.totalSupply;
      const promises: any[] = [];
      objectId._ownedObjects.forEach((objectId: number) => {
        promises.push(
          this.web3Service.getDigitalContentObject(objectId)
          .then((result) => {
            return result.object;
          })
        );
      });
      const res = await Promise.all(promises);
      res.forEach((object: any) => {
        if (object.specId == specId) {
          this.objectId.push(object.objectId);
          this.objectIndex.push(object.objectIndex);
          this.select.push({
            objectId: object.objectId,
            objectIndex: object.objectIndex,
          });
        }
      });

      /*
      const content = await this.contentService.getContentObjectByAddress(
        specId,
        this.walletAddress
      );
      */
      if (content) {
        // this.contentObject = content;
        // this.contentSpec = content.spec;
        this.contentSpec = this.spec;
        try {
          console.log('this.specInfo(0)', this.spec.information[0]);
          this.specInfo = JSON.parse(this.spec.information[0]);
          console.log('this.specInfo', { spec: this.spec, specInfo: this.specInfo });
          this.description = this.specInfo.description;
        } catch {
          this.specInfo = { info: this.spec.info };
        }

        let mediaId;
        if (this.spec.thumbnailId.startsWith('https')) {
          const urlArray = this.spec.thumbnailId.split("/");
          mediaId = urlArray[urlArray.length - 1];
        } else {
          mediaId = this.spec.thumbnailId;
        }

        try {
          const cAddress = await this.storageService.getContractAddress() || '';
          const imageFile = await this.publicStorageService.fileDownload(
            "dummy_token",
            mediaId,
            this.walletAddress,
            cAddress,
            this.spec.specId,
          );
          this.imageSource = imageFile;
        } catch (e) {
          console.log(e);
          this.onImgError();
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
        await this.confirmDialog.openComplete('error occurred. back to Content NFT list.');
        this.router.navigate(['/content-nft-list']);
        return;
      }
    } catch (e: any) {
      this.spinner.hide();
      console.log(e);
      if (e.error?.message === 'timeOut') {
        await this.confirmDialog.openComplete('time out error occurred. back to Content NFT list.');
      } else {
        await this.confirmDialog.openComplete('error occurred. back to Content NFT list.');
      }
      this.router.navigate(['/content-nft-list']);
      return;
    }
  }
  onImgError() {
  }
  onClickMore() {
    this.showMore = !this.showMore;
  }
  onClickProductImage() {
    // ContentNftPlayerComponentを表示する
    const ref = this.dialog.open(ContentNftPlayerComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      data: {
        contentSpec: this.contentSpec,
        specInfo: this.specInfo,
      },
    });
  }

  async logout() {
    await this.authService.logOut();
  }

  typeCheck(_object: any) {
    return typeof _object;
  }

  // ウォレットアドレスをコピー
  copyWalletAddress() {
    const decryptKey = this.walletAddress;
    this._clipboardService.copy(decryptKey);
    // snackBar表示
    this.openSnackBar('Copied your wallet address.');
  }

  // スナックバー
  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 4000, // 4s
      panelClass: ['brown-snackbar'],
      verticalPosition: 'bottom',
    });
  }

  async openAddressBook() {
    this.addressForm.patchValue({
      address: await this.selectAddressDialog.open(),
    });
  }

  toTransfer() {
    this.mode = 2;
  }

  valid() {
    return this.addressForm.valid;
  }

  async transfer() {
    const address = this.addressForm.getRawValue().address;
    if (!this.web3Service.isAddress(address)) {
      await this.confirmDialog.openComplete(
        'Invalid destination address.'
      );
      return;
    }

    const password = await this.passwordDialog.open();
    if (!password) {
      return;
    }
    const objectId = this.addressForm.getRawValue().selectedObjectId;

    // const password = this.passwordForm.getRawValue().password;
    const privateKey = (await this.storageService.getPrivateKey()) ?? '';
    //復号鍵はここでのみ使用する
    const decryptedPrivateKey = this.cryptService.decryption(
      privateKey,
      password
    );
    const checkPrivateKeyToAddress =
      await this.keyService.checkPrivateKeyToAddress(
        this.walletAddress,
        decryptedPrivateKey
      );
    if (!checkPrivateKeyToAddress) {
      this.confirmDialog.openComplete('Password is invalid');
      return;
    }
    // const objectId = this.contentObject?.objectId;
    const res = await this.confirmDialog.openConfirm(
      'Are you sure you want to transfer?'
    );
    if (res) {
      this.spinner.show();
      try{
        const transactionHash = await this.transferService.objectTransfer(
          this.walletAddress,
          decryptedPrivateKey,
          this.walletAddress,
          address,
          objectId
        );
        if (transactionHash) {
          await this.storageService.setTransactionHash(transactionHash as string);
          this.router.navigate(['/content-nft-send-result']);
        } else {
          await this.confirmDialog.openComplete('error occurred');
        }
      }catch(e: any){
        if(e.error.message === 'timeOut'){
          await this.confirmDialog.openComplete('time out error occurred');
        }else{
          await this.confirmDialog.openComplete('error occurred');
        }
      }
      this.spinner.hide();
    }
  }
}
